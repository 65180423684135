export const FrContinents = [
  {
    key: 58,
    value: "Haut-Rhin",
  },
  {
    key: 59,
    value: "Dordogne",
  },
  {
    key: 60,
    value: "Gironde",
  },
  {
    key: 61,
    value: "Landes",
  },
  {
    key: 62,
    value: "Lot-Et-Garonne",
  },
  {
    key: 63,
    value: "Pyrénées-Atlantiques",
  },
  {
    key: 64,
    value: "Allier",
  },
  {
    key: 65,
    value: "Cantal",
  },
  {
    key: 66,
    value: "Haute-Loire",
  },
  {
    key: 67,
    value: "Puy-De-Dôme",
  },
  {
    key: 68,
    value: "Calvados",
  },
  {
    key: 69,
    value: "Manche",
  },
  {
    key: 70,
    value: "Orne",
  },
  {
    key: 71,
    value: "Côte-d'Or",
  },
  {
    key: 72,
    value: "Nièvre",
  },
  {
    key: 73,
    value: "Saône-et-Loire",
  },
  {
    key: 74,
    value: "Yonne",
  },
  {
    key: 75,
    value: "Côtes-d'Armor",
  },
  {
    key: 76,
    value: "Finistère",
  },
  {
    key: 77,
    value: "Ille-Et-Vilaine",
  },
  {
    key: 78,
    value: "Morbihan",
  },
  {
    key: 79,
    value: "Cher",
  },
  {
    key: 80,
    value: "Eure-Et-Loir",
  },
  {
    key: 81,
    value: "Indre",
  },
  {
    key: 82,
    value: "Indre-Et-Loire",
  },
  {
    key: 83,
    value: "Loiret",
  },
  {
    key: 84,
    value: "Loir-Et-Cher",
  },
  {
    key: 85,
    value: "Ardennes",
  },
  {
    key: 86,
    value: "Aube",
  },
  {
    key: 87,
    value: "Haute-Marne",
  },
  {
    key: 88,
    value: "Marne",
  },
  {
    key: 89,
    value: "Corse-Du-Sud",
  },
  {
    key: 90,
    value: "Haute-Corse",
  },
  {
    key: 91,
    value: "Doubs",
  },
  {
    key: 92,
    value: "Haute-Saône",
  },
  {
    key: 93,
    value: "Jura",
  },
  {
    key: 94,
    value: "Territoire-de-Belfort",
  },
  {
    key: 95,
    value: "Eure",
  },
  {
    key: 96,
    value: "Seine-Maritime",
  },
  {
    key: 97,
    value: "Essonne",
  },
  {
    key: 98,
    value: "Hauts-De-Seine",
  },
  {
    key: 99,
    value: "Seine-Et-Marne",
  },
  {
    key: 100,
    value: "Seine-Saint-Denis",
  },
  {
    key: 101,
    value: "Val-De-Marne",
  },
  {
    key: 102,
    value: "Val-D'Oise",
  },
  {
    key: 103,
    value: "Ville-de-Paris",
  },
  {
    key: 104,
    value: "Yvelines",
  },
  {
    key: 105,
    value: "Aude",
  },
  {
    key: 106,
    value: "Gard",
  },
  {
    key: 107,
    value: "Hérault",
  },
  {
    key: 108,
    value: "Lozère",
  },
  {
    key: 109,
    value: "Pyrénées-Orientales",
  },
  {
    key: 110,
    value: "Corrèze",
  },
  {
    key: 111,
    value: "Creuse",
  },
  {
    key: 112,
    value: "Haute-Vienne",
  },
  {
    key: 113,
    value: "Meurthe-Et-Moselle",
  },
  {
    key: 114,
    value: "Meuse",
  },
  {
    key: 115,
    value: "Moselle",
  },
  {
    key: 116,
    value: "Vosges",
  },
  {
    key: 117,
    value: "Ariège",
  },
  {
    key: 118,
    value: "Aveyron",
  },
  {
    key: 119,
    value: "Gers",
  },
  {
    key: 120,
    value: "Haute-Garonne",
  },
  {
    key: 121,
    value: "Hautes-Pyrénées",
  },
  {
    key: 122,
    value: "Lot",
  },
  {
    key: 123,
    value: "Tarn",
  },
  {
    key: 124,
    value: "Tarn-Et-Garonne",
  },
  {
    key: 125,
    value: "Nord",
  },
  {
    key: 126,
    value: "Pas-De-Calais",
  },
  {
    key: 127,
    value: "Loire-Atlantique",
  },
  {
    key: 128,
    value: "Maine-Et-Loire",
  },
  {
    key: 129,
    value: "Mayenne",
  },
  {
    key: 130,
    value: "Sarthe",
  },
  {
    key: 131,
    value: "Vendée",
  },
  {
    key: 132,
    value: "Aisne",
  },
  {
    key: 133,
    value: "Oise",
  },
  {
    key: 134,
    value: "Somme",
  },
  {
    key: 135,
    value: "Charente",
  },
  {
    key: 136,
    value: "Charente-Maritime",
  },
  {
    key: 137,
    value: "Deux-Sèvres",
  },
  {
    key: 138,
    value: "Vienne",
  },
  {
    key: 139,
    value: "Alpes-De-Haute-Provence",
  },
  {
    key: 140,
    value: "Alpes-Maritimes",
  },
  {
    key: 141,
    value: "Bouches-Du-Rhône",
  },
  {
    key: 142,
    value: "Hautes-Alpes",
  },
  {
    key: 143,
    value: "Var",
  },
  {
    key: 144,
    value: "Vaucluse",
  },
  {
    key: 145,
    value: "Ain",
  },
  {
    key: 146,
    value: "Ardèche",
  },
  {
    key: 147,
    value: "Drôme",
  },
  {
    key: 148,
    value: "Haute-Savoie",
  },
  {
    key: 149,
    value: "Isère",
  },
  {
    key: 150,
    value: "Loire",
  },
  {
    key: 151,
    value: "Rhône",
  },
  {
    key: 152,
    value: "Savoie",
  },
];

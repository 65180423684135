import { Button, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { Card } from "react-bootstrap";

const TryOnPackage = () => {
  let subscriptionArray = [];
  const onFinish = (values) => {
    subscriptionArray.push(values);
  };
  return (
    <div className="subscription-cards-container">
      <Form onFinish={onFinish} style={{ display: "flex" }}>
        <Card id="subscription-card" className="mb-2">
          <Card.Header style={{ display: "flex" }}>
            <Form.Item name="yearlyAmount" label="price and duration">
              <Input style={{ width: "200px" }} />
            </Form.Item>
          </Card.Header>
          <Card.Body>
            <Form.Item label="Card Title" name="cardTitle1">
              <Input />
            </Form.Item>
            <Form.Item label="Card Description" name="cardDescription1">
              <TextArea rows={4} />
            </Form.Item>
          </Card.Body>
        </Card>{" "}
        <Card id="subscription-card" className="mb-2">
          <Card.Header style={{ display: "flex" }}>
            <Form.Item name="monthlyAmount">
              <Input style={{ width: "200px" }} />
            </Form.Item>
          </Card.Header>
          <Card.Body>
            <Form.Item label="Card Title" name="cardTitle2">
              <Input />
            </Form.Item>
            <Form.Item label="Card Description" name="cardDescription2">
              <TextArea rows={4} />
            </Form.Item>
          </Card.Body>
        </Card>{" "}
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default TryOnPackage;

import axios from "axios";

// Rating system
export const userStar = async (userId, star, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_API}/users/star/${userId}`,
    { star },

    {
      headers: {
        authtoken,
      },
    }
  );
export const userCart = async (cart, authtoken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/users/cart`,
    { cart },
    { headers: { authtoken } }
  );

export const getUserCart = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/users/cart`, {
    headers: { authtoken },
  });

export const emptyUserCart = async (authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/users/cart`, {
    headers: { authtoken },
  });

export const saveUserAddress = async (address, phone, authtoken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/users/address`,
    { address, phone },
    { headers: { authtoken } }
  );

export const ApplyCouponToBackend = async (name, authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/users/cart/coupon`,
    { name },
    {
      headers: { authToken },
    }
  );

export const createOrder = async (stripeResponse, shop_id, authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/user/order`,
    { stripeResponse, shop_id },
    { headers: { authToken } }
  );

export const getUserOrders = async (authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/user/orders`, {
    headers: { authToken },
  });

export const getWishList = async (authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/user/wishlist`, {
    headers: { authToken },
  });

export const removeFromWishList = async (productId, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_API}/user/wishlist/${productId}`,
    {},
    {
      headers: { authtoken },
    }
  );
export const addToWishList = async (productId, authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/user/wishlist`,
    { productId },
    {
      headers: { authToken },
    }
  );

export const getWishlistCount = async (authToken) =>
  await axios.get(`${process.env.REACT_APP_API}/user/wishlist-count`, {
    headers: { authToken },
  });

export const getUserContactInfo = async (userId) =>
  await axios.post(`${process.env.REACT_APP_API}/user/user-info`, { userId });

export const userActivateAr = async (authToken, ArDuration) =>
  await axios.post(
    `${process.env.REACT_APP_API}/user/activate-ar`,
    { ArDuration },
    {
      headers: { authToken },
    }
  );

import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import {
  createPaymentIntent,
  subscriptionPayment,
  TransferToSellers,
} from "../../functions/stripe";
import {
  createOrder,
  emptyUserCart,
  userActivateAr,
} from "../../functions/user";
import { Link, useHistory } from "react-router-dom";
import { Card } from "antd";
import { DollarOutlined, CheckOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "../../components/BsStripeCheckout.css";

const ArSubCheckout = () => {
  const { t } = useTranslation();

  const history = useHistory();
  console.log(history.location.state.ArDuration);

  const dispatch = useDispatch();
  const { user, useCoupon, cart } = useSelector((state) => ({ ...state }));

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [cartTotal, setCartTotal] = useState(0);
  const [payable, setPayable] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);

  const shopId = cart.map((i) => i.shop_id);

  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    subscriptionPayment(user.token, history.location.state.price).then(
      (res) => {
        setClientSecret(res.data.clientSecret);
        console.log(res.data.clientSecret);
      }
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.name,
        },
      },
    });

    //
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      userActivateAr(user.token, history.location.state.ArDuration).then(
        (res) => {
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          window.location.href = history.location.state.from;
        }
      );
    }
  };
  const handleChange = async (e) => {
    setDisabled(e.empty);
    setError(e.error ? e.error.message : "");

    //
  };
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div className="bs-stripe-page">
      <div className="bs-stripe-left"></div>

      <div className="bs-stripe-right">
        <div className="bs-stripe-right-card">
          <div id="bs-stripe-card-top">
            <div id="bs-stripe-card-top-total">
              Total amount
              <hr id="bs-stripe-card-top-total-line" />
              <h1>
                {" "}
                {t("DZD.0")}
                {history.location.state.price}
              </h1>
            </div>
          </div>
          <div id="bs-stripe-card-bottom">
            <form onSubmit={handleSubmit} style={{ padding: "13%" }}>
              <CardElement
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
              />
              <button
                id="pay-button"
                disabled={processing || disabled || succeeded}>
                <span id="bs-button-text">
                  {processing ? <div className="bs-spinner"></div> : "Pay"}
                </span>
              </button>
              <br />
              {error && (
                <div className="card-error" role="alert">
                  {error}
                </div>
              )}
              <p
                className={
                  succeeded ? "result-message" : "result-message hidden"
                }>
                Payment Successful.
                <Link to="/user/history">See it in your purchase history</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArSubCheckout;

// import React from "react";

// const BsStripeCheckout = ({ cart }) => {
//   return (
//     <div>
//       <div className="bs-payment">
//         <div className="bs-stripe-prods">
//           {cart.map((c, index) => (
//             <div key={index} className="bs-pament-prods-cards">
//               <img src={`${c.images[0]}`} alt={c.title} />
//               <p style={{ textAlign: "center" }}>{c.title}</p>
//               <h1 style={{ textAlign: "center" }}>
//                 <span style={{ fontSize: "12px" }}>DZD</span>
//                 {c.price}
//               </h1>
//             </div>
//           ))}
//         </div>
//         <div className="bs-stripe-card-container">
//           <div id="bs-stripe-card">
//             <div id="bs-stripe-card-top">
//               <div id="bs-stripe-card-top-total">
//                 this price before discount
//                 <hr id="bs-stripe-card-top-total-line" />
//                 <h1>DZD 123</h1>
//               </div>
//               <div id="bs-stripe-card-top-discount">
//                 this price after discount{" "}
//                 <hr id="bs-stripe-card-top-discount-line" />
//                 <h1>DZD 111</h1>
//               </div>
//             </div>
//             <div>info</div>
//             <div>Btn</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BsStripeCheckout;

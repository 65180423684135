/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Menu } from "antd";
import { logoutUser } from "../../../actions/user";

import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { Drawer, Button, Badge } from "antd";

import {
  UserOutlined,
  PlusOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

// import { auth } from "../../../../fireBase";
const { SubMenu, Item } = Menu;
function RightMenu(props) {
  // const alert = useAlert();
  const dispatch = useDispatch();
  const { user, cart } = useSelector((state) => ({ ...state }));
  const { t } = useTranslation();
  const history = useHistory();

  const logoutHandler = async () => {
    firebase.auth().signOut();
    dispatch(logoutUser());
    await history.push("/login");
  };

  return (
    <div>
      <div className="menu__logo">
        <Badge
          offset={[10, 14]}
          style={{ marginBottom: "50px" }}
          count={cart.length < 1 ? "nothing" : cart.length}
        >
          <a href="/cart" style={{ marginRight: -25, color: "#667777" }}>
            <ShoppingCartOutlined
              style={{ fontSize: 27, marginBottom: 3 }}
            ></ShoppingCartOutlined>
          </a>
        </Badge>
      </div>
      <Menu mode={props.mode}>
        {!user && (
          <Item key="mail">
            <a href="/login">{t("Sign in.1")}</a>
          </Item>
        )}

        {user && (
          <SubMenu
            key="uniqueProfile"
            icon={<UserOutlined />}
            title={user.email && user.email.split("@")[0]}
          >
            {user && user.role === 0 && (
              <>
                <Item key="user-dashboard" icon={<SettingOutlined />}>
                  <a href="/user/history">{t("Profile.0")}</a>
                </Item>

                {/* <Item key="upload" icon={<PlusOutlined />}>
                  <a href="/product">{t("Upload.1")}</a>
                </Item> */}
              </>
            )}

            {user && user.role === 1 && (
              <>
                <Item key="admin-dashboard" icon={<SettingOutlined />}>
                  <a href="/admin/dashboard">{t("Profile.0")}</a>
                </Item>

                {/* <Item key="upload" icon={<PlusOutlined />}>
                  <a href="/product">{t("Upload.1")}</a>
                </Item> */}
              </>
            )}

            {user && user.role === 2 && (
              <>
                <Item key="seller-dashboard" icon={<SettingOutlined />}>
                  <a href="/seller/dashboard">{t("Profile.0")}</a>
                </Item>

                <Item key="upload" icon={<PlusOutlined />}>
                  <a href="/seller/shop-create">{t("Upload.1")}</a>
                </Item>
              </>
            )}

            <Item key="logout">
              <a onClick={logoutHandler}>{t("Sign out.1")}</a>
            </Item>
          </SubMenu>
        )}
      </Menu>
    </div>
  );
}

export default withRouter(RightMenu);

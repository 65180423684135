import { SEARCH_QUERY, SEARCH_BY_SLUG } from "../actions/types";
export const searchReducer = (state = { text: "", slug: "" }, action) => {
  switch (action.type) {
    case SEARCH_QUERY:
      return { ...state, ...action.payload };
    case SEARCH_BY_SLUG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

import { combineReducers } from "redux";
import user from "./user";
import { searchReducer } from "./searchReducer";
import { ShowByHCondition } from "./ShowByHCondition";
import { cartReducer } from "./CartReducer";
import { drawerReducer } from "./drawerReducer";
import { couponReducer } from "./CouponReducer";
import { cityReducer } from "./cityReducer";
import { BottomBarReducer } from "./BottomBarReducer";
import { ConfirmReducer } from "./ConfirmReducer";
export const rootReducer = combineReducers({
  user,
  search: searchReducer,
  showProductsByHCondition: ShowByHCondition,
  cart: cartReducer,
  drawer: drawerReducer,
  useCoupon: couponReducer,
  city: cityReducer,
  clickedState: BottomBarReducer,
  alert: ConfirmReducer,
});

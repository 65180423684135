import React, { useState, useEffect } from "react";
import LeftMenu from "./sections/LeftMenu";
import RightMenu from "./sections/RightMenu";
import { Drawer, Badge } from "antd";
import {
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_BY_H_CONDITION } from "../../actions/types";
import { Switch } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import {
  AlignRightOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { logoutUser } from "../../actions/user";
import { Link, useHistory } from "react-router-dom";

import "./BottomBar.css";

const styles = { width: "230px" };

function NavBar() {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, cart, clickedState } = useSelector((state) => ({ ...state }));
  const history = useHistory();

  const [Lang, setLang] = useState();
  const [mynav, setMyNav] = useState(false);
  const [defferedPrompt, setdefferedPrompt] = useState();
  const [clicked, setClicked] = useState("");

  /////PWA

  const handleHomePageClick = (e) => {
    // console.log("asdfasfasfasdf");
  };

  function UsedOnChange(checked) {
    dispatch({
      type: SHOW_BY_H_CONDITION,
      payload: { usedProducts: checked },
    });
  }

  function NewOnChange(checked) {
    dispatch({
      type: SHOW_BY_H_CONDITION,
      payload: { newProducts: checked },
    });
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  function handleLang(lang) {
    i18next.changeLanguage(lang);
    setLang(lang);
  }
  const logoutHandler = async () => {
    firebase.auth().signOut();
    dispatch(logoutUser());
    await history.push("/login");
  };

  const showNav = () => {
    if (window.scrollY >= 100) {
      setMyNav(true);
    } else {
      setMyNav(false);
    }
  };
  window.addEventListener("scroll", showNav);

  return (
    <div className="bottom-nav-container">
      <div
        onClick={() => {
          dispatch({ type: "CLICKED", payload: "1" });
          history.push("/store");
        }}>
        <svg
          id="bottom-nav-items"
          xmlns="http://www.w3.org/2000/svg"
          width="25.227"
          height="25.227"
          viewBox="0 0 25.227 25.227">
          <g
            id="Iconly_Bold_Filter_2"
            data-name="Iconly Bold Filter 2"
            transform="translate(-850.231 -419.794)">
            <g id="Filter_2" data-name="Filter 2">
              <path
                id="Filter_2-2"
                data-name="Filter 2-2"
                d="M859.167,444.862a1.01,1.01,0,0,1-.449-.845v-6.074a.7.7,0,0,1,.7-.712h0l6.124.019a.7.7,0,0,1,.691.712v3.445a1.01,1.01,0,0,1-.573.913l-5.557,2.611a.974.974,0,0,1-.407.09A.955.955,0,0,1,859.167,444.862Zm6.748-9.876-6.87-.021a.741.741,0,0,1-.551-.252l-7.539-8.287a2.812,2.812,0,0,1-.724-1.892V422.56a2.726,2.726,0,0,1,2.684-2.766h19.858a2.726,2.726,0,0,1,2.684,2.765h0v1.933a2.809,2.809,0,0,1-.8,1.971l-8.205,8.3a.731.731,0,0,1-.525.222Z"
                fill={clickedState[0] === "1" ? "#0F81A3" : "#b5b5b5"}
              />
            </g>
          </g>
        </svg>
        <p id="bottom-nav-items-text">Filter</p>
      </div>
      <div
        onClick={() => {
          dispatch({ type: "CLICKED", payload: "2" });

          history.push("/mapSearch");
        }}>
        <svg
          id="bottom-nav-items"
          xmlns="http://www.w3.org/2000/svg"
          width="25.44"
          height="25.448"
          viewBox="0 0 25.44 25.448">
          <path
            id="Path_591"
            data-name="Path 591"
            d="M1288.129,1633.82c.082-.6.141-1.2.25-1.789a12.179,12.179,0,0,1,1.441-3.862.336.336,0,0,1,.183-.141,3.912,3.912,0,0,1,.716-.132l-.828.667a1.985,1.985,0,0,0,1.239.5,14.242,14.242,0,0,0,2.676-.348.419.419,0,0,1,.528.236c.334.581.7,1.143,1.043,1.719a.788.788,0,0,1,.1.368c.011.538,0,1.077.01,1.615a.721.721,0,0,0,.124.382c.512.712,1.041,1.413,1.554,2.124a.348.348,0,0,0,.447.158v-.733l.052-.008c.184.384.375.766.549,1.154a.405.405,0,0,0,.32.263c.551.112,1.1.232,1.645.371a1,1,0,0,1,.437.243c.458.452.865.974,1.639.976a8.889,8.889,0,0,1-.512,1.057,1.185,1.185,0,0,0,.034,1.5,9.1,9.1,0,0,1,1.094,2.934.559.559,0,0,1-.013.266c-.13.547-.237,1.1-.408,1.636a4.593,4.593,0,0,0-.035,1.721c.007.122.019.243.029.378l-.946.121h-1.392a1.386,1.386,0,0,0-.192-.041,11.941,11.941,0,0,1-4.992-1.433,12.557,12.557,0,0,1-6.6-9.063c-.094-.493-.134-1-.2-1.493Z"
            transform="translate(-1288.129 -1621.744)"
            fill={clickedState[0] === "2" ? "#5AAD81" : "#b5b5b5"}
          />
          <path
            id="Path_592"
            data-name="Path 592"
            d="M1353.943,1610.636a2.592,2.592,0,0,1,.411-.09.478.478,0,0,0,.495-.486c.047-.359.157-.709.249-1.1.327.134.648.254.956.4.052.025.078.176.062.258a.408.408,0,0,0,.355.551,1.234,1.234,0,0,1,.3.124,1,1,0,0,0,1.269-.162c-.207-.237-.415-.492-.641-.729a8.865,8.865,0,0,0-.8-.778.712.712,0,0,1-.229-.829c.493.164,1.017.113,1.485.485a3.3,3.3,0,0,1,1.144,1.88,1.048,1.048,0,0,0,.358.572c.373.316.721.661,1.095.975a6.953,6.953,0,0,0,.646.433c.186-.294.35-.53.489-.781a.393.393,0,0,1,.357-.213c.561-.043,1.122-.1,1.682-.147a.365.365,0,0,0,.146-.02c.284-.172.409-.013.551.217a12.735,12.735,0,0,1-7.646,19.073,1.155,1.155,0,0,1-.134.013c.071-.263.123-.5.2-.723a.447.447,0,0,1,.178-.192c.617-.417,1.24-.825,1.854-1.248a.7.7,0,0,0,.235-.3c.161-.4.295-.808.456-1.206a.5.5,0,0,1,.22-.238c.369-.186.753-.342,1.12-.532a.808.808,0,0,0,.327-.306c.447-.828.878-1.666,1.326-2.523-.336-.2-.651-.412-.989-.58a5.781,5.781,0,0,1-2.138-1.766.708.708,0,0,0-.559-.3,8.373,8.373,0,0,1-.945-.193c-.6-.125-1.193-.277-1.8-.36a1.63,1.63,0,0,0-.741.152.474.474,0,0,1-.666-.193,1.255,1.255,0,0,0-.981-.574c.016-.321.032-.622.047-.919a.94.94,0,0,0-1.17.526l-.255-1.076c.311-.148.606-.294.907-.426a.294.294,0,0,1,.207.018c.236.1.459.235.7.313a2.724,2.724,0,0,0,.628.087.171.171,0,0,0,.126-.112c.052-.684.557-1.1.924-1.6a4.737,4.737,0,0,1,1.781-1.445,2.758,2.758,0,0,1,2.157-.4.43.43,0,0,0,.123,0c.205-.4.187-.539-.11-.841-.168-.171-.327-.354-.507-.512a4.307,4.307,0,0,1-1.212-1.7.285.285,0,0,0-.338-.209,1.924,1.924,0,0,1-1.06-.24,4.916,4.916,0,0,0-1.174-.24l-.245,1.339a7.108,7.108,0,0,1-1.243-.255"
            transform="translate(-1340.813 -1605.233)"
            fill={clickedState[0] === "2" ? "#5AAD81" : "#b5b5b5"}
          />
          <path
            id="Path_593"
            data-name="Path 593"
            d="M1304.737,1598.027a12.606,12.606,0,0,1,15.1-3.391,1.957,1.957,0,0,1-1.137-.042,1.077,1.077,0,0,0-.874.181,5.616,5.616,0,0,0-.98.869.571.571,0,0,1-.327.172c-.617.074-1.234.148-1.854.179a4.259,4.259,0,0,1-.958-.1.271.271,0,0,0-.334.16,6.017,6.017,0,0,1-.415.648.464.464,0,0,1-.288.179,12.875,12.875,0,0,1-1.587.074,4.211,4.211,0,0,1-1.006-.245.533.533,0,0,0-.511.063,3.46,3.46,0,0,1-1.815.581,12.928,12.928,0,0,0-2.756.608C1304.914,1597.987,1304.837,1598,1304.737,1598.027Zm12.41-3.553c-.693-.542-.724-.545-1.434-.155-.2.111-.407.219-.606.336-.265.156-.526.321-.8.487.412.345.467.365.937.309a2,2,0,0,0,.747-.124A12.255,12.255,0,0,0,1317.147,1594.474Zm-6.775,1.007c.265.155.51.3.76.441a.206.206,0,0,0,.139.014c.543-.14,1.085-.284,1.647-.432-.1-.176-.2-.327-.277-.491s-.184-.235-.364-.144a.963.963,0,0,1-1.005-.037.086.086,0,0,0-.047-.009Z"
            transform="translate(-1301.773 -1593.429)"
            fill={clickedState[0] === "2" ? "#5AAD81" : "#b5b5b5"}
          />
        </svg>
        <p id="bottom-nav-items-text">Search</p>
      </div>
      <div
        onClick={() => {
          dispatch({ type: "CLICKED", payload: "3" });

          // history.push("/");
        }}>
        <a href="/">
          <svg
            id="bottom-nav-items"
            xmlns="http://www.w3.org/2000/svg"
            width="23.965"
            height="25.226"
            viewBox="0 0 23.965 25.226">
            <g
              id="Iconly_Bold_Home"
              data-name="Iconly Bold Home"
              transform="translate(-548.146 -238.166)">
              <g id="Home-3">
                <path
                  id="Home-4"
                  d="M556.515,261.844v-3.856a1.79,1.79,0,0,1,1.794-1.786h3.626a1.8,1.8,0,0,1,1.27.522,1.776,1.776,0,0,1,.526,1.262v3.857a1.528,1.528,0,0,0,.449,1.093,1.551,1.551,0,0,0,1.1.454h2.473a4.361,4.361,0,0,0,3.081-1.261,4.3,4.3,0,0,0,1.278-3.054V248.088a3.117,3.117,0,0,0-1.135-2.4l-8.408-6.674a3.91,3.91,0,0,0-4.981.089l-8.221,6.582a3.121,3.121,0,0,0-1.22,2.4v10.973a4.345,4.345,0,0,0,4.358,4.331h2.418a1.553,1.553,0,0,0,1.559-1.537Z"
                  fill={clickedState[0] === "3" ? "#4e3389" : "#b5b5b5"}
                />
              </g>
            </g>
          </svg>
          <p id="bottom-nav-items-text" style={{ color: "black" }}>
            Home
          </p>
        </a>
      </div>
      <div
        onClick={() => {
          dispatch({ type: "CLICKED", payload: "4" });

          user ? history.push("/user/wishlist") : history.push("/login");
        }}>
        <svg
          id="bottom-nav-items"
          xmlns="http://www.w3.org/2000/svg"
          width="25.23"
          height="23.965"
          viewBox="0 0 25.23 23.965">
          <g
            id="Iconly_Bold_Heart"
            data-name="Iconly Bold Heart"
            transform="translate(-729.139 -238.797)">
            <g id="Heart-2">
              <path
                id="Heart-3"
                d="M741.8,262.762h0l-.327-.2a48.125,48.125,0,0,1-8.036-6.257,16.382,16.382,0,0,1-3.8-6.053,9.765,9.765,0,0,1,.18-6.653,7.671,7.671,0,0,1,4.778-4.46,5.676,5.676,0,0,1,1.121-.264h.151a7.23,7.23,0,0,1,1.06-.076h.139a7.931,7.931,0,0,1,2.309.416h.075a.43.43,0,0,1,.113.076,4.309,4.309,0,0,1,.795.329l.479.213a3.35,3.35,0,0,1,.359.24c.072.053.134.1.183.126l.052.031a2.966,2.966,0,0,1,.327.209,7.846,7.846,0,0,1,4.8-1.64h.056a7.386,7.386,0,0,1,2.346.367A7.592,7.592,0,0,1,753.7,243.6a9.981,9.981,0,0,1,.2,6.65,16.13,16.13,0,0,1-3.8,6.067,48.726,48.726,0,0,1-7.986,6.256l-.316.189Zm6.189-20.032a1.021,1.021,0,0,0-.955.685,1.035,1.035,0,0,0,.631,1.3,2.113,2.113,0,0,1,1.349,1.98v.038a1.086,1.086,0,0,0,.24.782,1.061,1.061,0,0,0,.719.366,1.038,1.038,0,0,0,1-.959v-.15a4.162,4.162,0,0,0-2.661-3.987A.993.993,0,0,0,747.985,242.73Z"
                fill={clickedState[0] === "4" ? "#9B2E44" : "#b5b5b5"}
              />
            </g>
          </g>
        </svg>
        <p id="bottom-nav-items-text">Favorite</p>
      </div>
      <div
        onClick={() => {
          dispatch({ type: "CLICKED", payload: "5" });

          history.push("/cart");
        }}>
        {" "}
        <Badge
          offset={[-20, 20]}
          count={cart.length < 1 ? "0" : cart.length}
          showZero>
          <svg
            id="bottom-nav-items"
            xmlns="http://www.w3.org/2000/svg"
            width="25.238"
            height="25.227"
            viewBox="0 0 25.238 25.227">
            <g
              id="Iconly_Bold_Buy"
              data-name="Iconly Bold Buy"
              transform="translate(-249.845 -544.664)">
              <g id="Buy">
                <path
                  id="Buy-2"
                  d="M268.953,567.947a1.91,1.91,0,1,1,1.916,1.944A1.937,1.937,0,0,1,268.953,567.947Zm-14.178,0a1.909,1.909,0,1,1,1.914,1.944A1.935,1.935,0,0,1,254.775,567.947Zm2.118-3.991a3.163,3.163,0,0,1-2.178-.869,3.307,3.307,0,0,1-1.024-2.144l-1.16-14.028-1.9-.336a.966.966,0,0,1,.182-1.915,1.189,1.189,0,0,1,.146.012l3.005.461a.97.97,0,0,1,.781.876l.24,2.883a.778.778,0,0,0,.768.722h17.021a2.131,2.131,0,0,1,1.777.862,2.839,2.839,0,0,1,.48,2.213l-1.2,8.443a3.255,3.255,0,0,1-3.188,2.819Zm7.3-8.61a.956.956,0,0,0,.946.965h3.49a.955.955,0,0,0,.946-.965.945.945,0,0,0-.925-.965h-3.512a.946.946,0,0,0-.945.947v.018Z"
                  fill={clickedState[0] === "5" ? "#D3B245" : "#b5b5b5"}
                />
              </g>
            </g>
          </svg>
        </Badge>
        <p id="bottom-nav-items-text">Cart</p>
      </div>
    </div>
  );
}

export default NavBar;

import axios from "axios";

export const createProduct = async (product, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/product`, product, {
    headers: {
      authtoken,
    },
  });
export const getProductsByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/products/${count}`);

//
export const getProducts = async (
  sort,
  order,
  page,
  newProducts,
  usedProducts,
  country
) =>
  await axios.post(`${process.env.REACT_APP_API}/products`, {
    sort,
    order,
    page,
    newProducts,
    usedProducts,
    country,
  });
///////////All products/////////
export const getAllProducts = async () =>
  await axios.get(`${process.env.REACT_APP_API}/allproducts`);
////////////

export const removeProduct = async (id, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/product/${id}`, {
    headers: {
      authtoken,
    },
  });

////////getProductsByShopId////////////////////////////////
export const getProductsByShopId = async (sort, order, page, shop_id) =>
  await axios.post(`${process.env.REACT_APP_API}/products/${shop_id}`, {
    sort,
    order,
    page,
  });
/////////////////////////////////////////////

export const getProduct = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/product/${id}`);

export const getProductsCount = async () =>
  await axios.get(`${process.env.REACT_APP_API}/count-products/total`);

export const updateProduct = async (id, product, authtoken) =>
  await axios.put(`${process.env.REACT_APP_API}/product/${id}`, product, {
    headers: {
      authtoken,
    },
  });

export const myItems = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/myItems/${id}`);

// export const prodsOfCity = async (id) =>
//   await axios.get(`${process.env.REACT_APP_API}/product/byCity/${id}`);
///////
export const prodsOfCity = async (
  id,
  sort,
  order,
  page,
  newProducts,
  usedProducts,
  country
) =>
  await axios.post(`${process.env.REACT_APP_API}/product/byCity/${id}`, {
    sort,
    order,
    page,
    newProducts,
    usedProducts,
    country,
  });

export const AllprodsOfCities = async (Continents) =>
  await axios.post(
    `${process.env.REACT_APP_API}/product/all-prods-Cities`,
    Continents,
    {}
  );
//////

export const myItemUpdate = async (id, product, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_API}/product/myitem-update/${id}`,
    product,
    {
      headers: {
        authtoken,
      },
    }
  );

export const myItemRemove = async (id, authtoken) =>
  await axios.delete(
    `${process.env.REACT_APP_API}/product/myitem-remove/${id}`,
    {
      headers: {
        authtoken,
      },
    }
  );
export const getRelated = async (id) =>
  await axios.get(`${process.env.REACT_APP_API}/product/related/${id}`);

export const fetchProductsByFilter = async (
  sort,
  order,
  page,
  query,

  newProducts,
  usedProducts,
  country
) =>
  await axios.post(`${process.env.REACT_APP_API}/search/filters`, {
    sort,
    order,
    page,
    query,

    newProducts,
    usedProducts,
    country,
  });

export const productStar = async (ratedProduct, star, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_API}/prouduct/star/${ratedProduct}`,
    { star },

    {
      headers: {
        authtoken,
      },
    }
  );
export const productReview = async (reviewdProduct, review, authtoken) =>
  await axios.put(
    `${process.env.REACT_APP_API}/prouduct/reviews/${reviewdProduct}`,
    { review },

    {
      headers: {
        authtoken,
      },
    }
  );

export const IqContinents = [
  { key: 354, value: "Baghdad" },
  { key: 355, value: "AlMuthanna" },
  { key: 356, value: "AlNajaf" },
  { key: 357, value: "Al-Anbar" },
  { key: 358, value: "Babil" },
  { key: 359, value: "AlBasrah" },
  { key: 360, value: "ThiQar" },
  { key: 361, value: "Diwaniyah" },
  { key: 362, value: "Diyala" },
  { key: 363, value: "Dihok" },
  { key: 364, value: "Arbil" },
  { key: 365, value: "Karbala" },
  { key: 366, value: "Kirkuk" },
  { key: 367, value: "Maysan" },
  { key: 368, value: "Ninawa" },
  { key: 369, value: "Salaheddin" },
  { key: 370, value: "As-Sulaymaniyah" },
  { key: 371, value: "Wasit" },
];

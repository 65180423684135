import { SHOW_BY_H_CONDITION } from "../actions/types";
export const ShowByHCondition = (
  state = { newProducts: false, usedProducts: false },
  action
) => {
  switch (action.type) {
    case SHOW_BY_H_CONDITION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

import axios from "axios";

export const createPaymentIntent = async (authToken, coupon) =>
  await axios.post(
    `${process.env.REACT_APP_API}/create-payment-intent`,
    { AppliedCoupon: coupon },
    {
      headers: { authToken },
    }
  );

export const createConnectAccount = async (authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/create-connect-account`,
    {},
    {
      headers: { authToken },
    }
  );

export const getAccountStatus = async (authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/get-account-status`,
    {},
    { headers: { authToken } }
  );

export const getAccountBalance = async (authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/get-account-balance`,
    {},
    { headers: { authToken } }
  );

export const payoutSettings = async (authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/payout-settings`,
    {},
    { headers: { authToken } }
  );

export const currencyFormatter = (data) => {
  return (data.amount / 100).toLocaleString(data.currency, {
    style: "currency",
    currency: data.currency,
  });
};

export const TransferToSellers = async (authToken) =>
  await axios.post(
    `${process.env.REACT_APP_API}/transfer-to-sellers`,
    {},
    { headers: { authToken } }
  );

export const subscriptionPayment = async (authToken, amount) =>
  await axios.post(
    `${process.env.REACT_APP_API}/create-subscription-payment-intent`,
    { amount },
    {
      headers: { authToken },
    }
  );

import React, { useState, useMemo, useRef, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import CountrySelect from "react-bootstrap-country-select";

// https://github.com/jaywilz/react-bootstrap-country-select

const Countries = [
  {
    id: "fr",
    name: "France",
    flag: "🇫🇷",
    alpha2: "",
    alpha3: "",
    ioc: "",
    currency: "EUR",
  },
  {
    id: "tr",
    name: "Turkey",
    flag: "🇹🇷",
    alpha2: "",
    alpha3: "",
    ioc: "",
    currency: "TL",
  },
  {
    id: "dz",
    name: "Algeria",
    flag: "🇩🇿",
    alpha2: "",
    alpha3: "",
    ioc: "",
    currency: "DZD",
  },
  {
    id: "iq",
    name: "Iraq",
    flag: "🇮🇶",
    alpha2: "",
    alpha3: "",
    ioc: "",
    currency: "IQD",
  },
];

function CountrySelector(props) {
  const [windowWidth, setWindowWidth] = useState("480");

  const [countryId, setCountryId] = useState("");
  const countrySelectRef = useRef();
  useEffect(() => {
    if (localStorage.getItem("region") !== null) {
      const region = JSON.parse(localStorage.getItem("region"));
      if (region?.country !== null || undefined) {
        setCountryId(region?.country.toLowerCase());
      }
    } else {
      console.log("this is Country Selector");
      setTimeout(() => {
        if (localStorage.getItem("region") !== null) {
          const region = JSON.parse(localStorage.getItem("region"));
          if (region?.country !== null || undefined) {
            setCountryId(region?.country.toLowerCase());
          }
        }
      }, 5000);

      return;
    }
  }, []);

  const changeHandler = (value) => {
    setCountryId(value);
    let valueStr;
    if (value !== null) {
      valueStr = value.id.toString().toUpperCase();
      let d2;
      d2 = {
        country: valueStr,
        changedByUser: true,
        selectedCountry: value.id,
        currency: value.currency,
      };
      localStorage.setItem("region", JSON.stringify(d2));
      window.location.reload();
    }
  };
  const handleFocus = () => {
    setCountryId("");
    if (props && props.ChangeZindex) {
      props.ChangeZindex(true);
    }
    // Additional logic when the CountrySelector is focused
  };
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
  }, [windowWidth]);
  return (
    <div
      ref={(el) => {
        countrySelectRef.current = el;
      }}
      onFocus={handleFocus}>
      <CountrySelect
        disabled={windowWidth <= 480 ? false : true}
        value={countryId}
        onChange={changeHandler}
        size={"sm"}
        placeholder="Country"
        valueAs={("id", "currency")}
        countries={Countries}
      />
    </div>
  );
}

export default CountrySelector;

export const TrContinents = [
  { key: 153, value: "Kirklareli" },
  { key: 154, value: "Sinop" },
  { key: 155, value: "Kastamonu" },
  { key: 156, value: "Edirne" },
  { key: 157, value: "Bartin" },
  { key: 158, value: "Samsun" },
  { key: 159, value: "Zonguldak" },
  { key: 160, value: "Artvin" },
  { key: 161, value: "Karabuk" },
  { key: 162, value: "Ardahan" },
  { key: 163, value: "Rize" },
  { key: 164, value: "Çorum" },
  { key: 165, value: "Kocaeli" },
  { key: 166, value: "Sakarya" },
  { key: 167, value: "Düzce" },
  { key: 168, value: "Giresun" },
  { key: 169, value: "Trabzon" },
  { key: 170, value: "Amasya" },
  { key: 171, value: "Çankırı" },
  { key: 172, value: "Kars" },
  { key: 173, value: "Bolu" },
  { key: 174, value: "Erzurum" },
  { key: 175, value: "Tokat" },
  { key: 176, value: "Gümüşhane" },
  { key: 177, value: "Yalova" },
  { key: 178, value: "Ankara" },
  { key: 179, value: "Bursa" },
  { key: 180, value: "Sivas" },
  { key: 181, value: "Bilecik" },
  { key: 182, value: "Kırıkkale" },
  { key: 183, value: "Yozgat" },
  { key: 184, value: "Eskişehir" || "Eskisehir" },
  { key: 185, value: "Ağrı" },
  { key: 186, value: "Erzincan" },
  { key: 187, value: "Iğdır" },
  { key: 188, value: "Kütahya" || "Kutahya" },
  { key: 189, value: "Kırşehir" },
  { key: 190, value: "Tunceli" },
  { key: 191, value: "Bingöl" },
  { key: 192, value: "Muş" },
  { key: 193, value: "Nevşehir" },
  { key: 194, value: "Van" },
  { key: 195, value: "Manisa" },
  { key: 196, value: "Kayseri" },
  { key: 197, value: "Afyonkarahisar" },
  { key: 198, value: "Konya" },
  { key: 199, value: "Elazig" },
  { key: 200, value: "Malatya" },
  { key: 201, value: "Aksaray" },
  { key: 202, value: "Bitlis" },
  { key: 203, value: "Uşak" },
  { key: 204, value: "Diyarbakır" },
  { key: 205, value: "Kahramanmaraş" },
  { key: 206, value: "Batman" },
  { key: 207, value: "Isparta" },
  { key: 208, value: "Denizli" },
  { key: 209, value: "Niğde" },
  { key: 210, value: "Adana" },
  { key: 211, value: "Siirt" },
  { key: 212, value: "Adıyaman" },
  { key: 213, value: "Şanlıurfa" },
  { key: 214, value: "Hakkari" },
  { key: 215, value: "Burdur" },
  { key: 216, value: "Şırnak" },
  { key: 217, value: "Mardin" },
  { key: 218, value: "Osmaniye" },
  { key: 219, value: "Karaman" },
  { key: 220, value: "Gaziantep" },
  { key: 221, value: "Mersin" },
  { key: 222, value: "Kilis" },
  { key: 223, value: "Hatay" },
  { key: 224, value: "Balıkesir" },
  { key: 225, value: "İzmir" },
  { key: 226, value: "Aydın" },
  { key: 227, value: "Muğla" },
  { key: 228, value: "Antalya" },
  { key: 229, value: "İstanbul" },
  { key: 230, value: "Tekirdağ" },
  { key: 231, value: "Çanakkale" },
];

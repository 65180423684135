import React, { useState, useEffect } from "react";
import LeftMenu from "./sections/LeftMenu";
import RightMenu from "./sections/RightMenu";
import { Drawer, Badge } from "antd";
import {
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_BY_H_CONDITION } from "../../actions/types";
import { Switch } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import {
  AlignRightOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { logoutUser } from "../../actions/user";
import { useHistory } from "react-router-dom";
import "./NavBarScndStyle.css";
import Search from "../forms/Search";

const styles = { width: "230px" };

function NavBarScnd({
  isTitle = true,
  title = "",
  titleColor = "",
  navColor = "#fff",
  isScrolling = true,
  isBGColor = false,
  bgColortxt = "",
  isBack = true,
}) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, cart } = useSelector((state) => ({ ...state }));
  const history = useHistory();
  const [Lang, setLang] = useState("Ar");
  const [mynav, setMyNav] = useState(false);

  function UsedOnChange(checked) {
    dispatch({
      type: SHOW_BY_H_CONDITION,
      payload: { usedProducts: checked },
    });
  }

  function NewOnChange(checked) {
    dispatch({
      type: SHOW_BY_H_CONDITION,
      payload: { newProducts: checked },
    });
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  function handleLang() {
    if (Lang === "En") setLang("Ar");
    if (Lang === "Ar") setLang("Fr");
    if (Lang === "Fr") setLang("En");

    i18next.changeLanguage(Lang.toLowerCase());
  }
  const logoutHandler = async () => {
    firebase.auth().signOut();
    dispatch(logoutUser());
    await history.push("/login");
  };

  const showNav = () => {
    if (window.scrollY >= 100) {
      setMyNav(true);
    } else {
      setMyNav(false);
    }
  };
  window.addEventListener("scroll", showNav);

  const OpenMenu = () => {
    // console.log("Open menu......");
    dispatch({ type: "SET_VISIBLE", payload: true });
  };

  const GoBackHandler = () => {
    history.goBack();
  };

  return (
    <div className="nav-bar-scnd-menu-container">
      <div
        style={{ backgroundColor: isBGColor && bgColortxt }}
        className={
          mynav && isScrolling
            ? "nav-bar-scnd-menu nav-bar-scnd-menu-change-color"
            : "nav-bar-scnd-menu"
        }>
        <svg
          onClick={OpenMenu}
          id="nav-bar-scnd-menu-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22.463 14.67">
          <g
            id="Group_5"
            data-name="Group 5"
            transform="translate(-66.777 -88.884)">
            <line
              id="Line_3"
              data-name="Line 3"
              x2="19.463"
              transform="translate(68.277 90.384)"
              fill="none"
              stroke={navColor}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
            <line
              id="Line_4"
              data-name="Line 4"
              x2="19.255"
              transform="translate(68.277 96.219)"
              fill="none"
              stroke={navColor}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
            <line
              id="Line_5"
              data-name="Line 5"
              x2="18.792"
              transform="translate(68.741 102.054)"
              fill="none"
              stroke={navColor}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
          </g>
        </svg>
        <p
          id="nav-bar-scnd-title"
          style={{ color: isTitle ? titleColor : "white" }}>
          {isTitle && title}
        </p>
        {/* <p id="language-option" onClick={handleLang}>
          {Lang}
        </p>{" "} */}
        {isBack && (
          <p id="nav-bar-scnd-back" onClick={GoBackHandler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.452"
              height="15.823"
              viewBox="0 0 18.452 15.823">
              <g
                id="Group_712"
                data-name="Group 712"
                transform="translate(1045.156 2696.584) rotate(180)">
                <path
                  id="Path_534"
                  data-name="Path 534"
                  d="M1045.156,2688.888a1.748,1.748,0,0,1-.411.725,1.383,1.383,0,0,1-.871.383c-.1.007-.2.007-.306.007h-12.723a.728.728,0,0,0,.1.073,3.147,3.147,0,0,1,1.036.732q1.714,1.726,3.434,3.446a1.369,1.369,0,0,1,.415,1.456,1.279,1.279,0,0,1-1.984.616,2.5,2.5,0,0,1-.243-.214q-3.218-3.216-6.435-6.434a1.322,1.322,0,0,1-.279-1.685,1.694,1.694,0,0,1,.27-.335q3.227-3.233,6.459-6.461a1.317,1.317,0,0,1,1.988.034,1.351,1.351,0,0,1-.01,1.681c-.068.083-.144.161-.22.238q-1.464,1.466-2.931,2.93a4.421,4.421,0,0,1-1.57,1.236l.013.046h.2q6.3,0,12.6,0a1.335,1.335,0,0,1,1.171.508,3.6,3.6,0,0,1,.3.624Z"
                  fill={navColor}
                />
              </g>
            </svg>
          </p>
        )}
        {/* make this button toggled   */}
        {/* <p id="sign-in-nav">Sign in</p> */}
      </div>
    </div>
  );
}

export default NavBarScnd;

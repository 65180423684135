import { FaceMesh } from "@mediapipe/face_mesh";
import React, { useRef, useEffect, useState } from "react";
import * as Facemesh from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";
import baseImage from "./b6.png";
import Webcam from "react-webcam";
import NavBarScnd from "../pages/NavBar/NavBarScnd";
import BottomBar from "../pages/NavBar/BottomBar";
import Label from "../components/bigScreens/Label";
import BsNavBar from "../components/bigScreens/BSNavBar";
import "./Glasses.css";
import "./Hijab.css";
import loadedImage from "./hijab6.png";

function Glasses({ history }) {
  const [widthtUp, setWidthUp] = useState(0);
  const [heightUp, setHeightUp] = useState(0);

  const widthup = () => {
    setWidthUp(widthtUp + 10);
  };

  const widthdown = () => {
    setWidthUp(widthtUp - 10);
  };
  const heightup = () => {
    setHeightUp(heightUp + 10);
  };

  const heightdown = () => {
    setHeightUp(heightUp - 10);
  };

  ///moving ///

  let offsetX, offsetY;
  const onTouchHandler = (ev) => {
    offsetX = ev.touches[0].clientX - ev.target.getBoundingClientRect().left;
    offsetY = ev.touches[0].clientY - ev.target.getBoundingClientRect().top;

    ev.target.addEventListener("touchmove", Touchmove);
  };
  const Touchmove = (e) => {
    e.target.style.left = `${e.touches[0].pageX - offsetX}px`;
    e.target.style.top = `${e.touches[0].pageY - offsetY}px`;
  };
  const Touchremove = (e) => {
    const el = e.target;
    el.removeEventListener("touchmove", move);
  };
  const onMouseDownHandler = (e) => {
    const el = e.target;
    offsetX = e.clientX - el.getBoundingClientRect().left;
    offsetY = e.clientY - el.getBoundingClientRect().top;

    el.addEventListener("mousemove", move);
  };
  const move = (e) => {
    const el = e.target;
    el.style.left = `${e.pageX - offsetX}px`;
    el.style.top = `${e.pageY - offsetY}px`;
  };
  const remove = (e) => {
    const el = e.target;
    el.removeEventListener("mousemove", move);
  };
  /////

  ////reposition
  const repositionHandler = () => {
    setReposition(true);
  };
  //////
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [Zindex, setZindex] = useState(2);
  const [windowWidth, setWindowWidth] = useState("480");
  const [windowHeight, setWindowHeight] = useState("480");

  const [reposition, setReposition] = useState(false);
  const imageRef = useRef(null);
  const connect = window.drawConnectors;
  let newY = 0;

  var camera = null;

  // to change z-index
  const ChangeZindex = (click) => {
    if (click) {
      setZindex(0);
    } else {
      setZindex(2);
    }
  };
  const updateDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setWindowHeight(height);
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
  }, [windowWidth]);

  function onResults(results) {
    // const video = webcamRef.current.video;
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    // Set canvas width
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    function clear() {
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    }

    const getDistanceY = (endLEyePoint) => {
      let newDist = endLEyePoint.y - newY;
      newY = newDist;
    };

    function make_base(
      startREyePoint,
      endLEyePoint,
      REyeHeighttop,
      REyeHeightbottom,
      nosePoint,
      landmarks,
      ydistREye
    ) {
      canvasCtx.beginPath();
      let distx = landmarks[454].x - landmarks[234].x;
      let disty = landmarks[1].y - landmarks[168].y;
      let disY = landmarks[152].y - landmarks[10].y;

      canvasCtx.drawImage(
        imageRef.current,
        startREyePoint.x * videoWidth,
        startREyePoint.y * videoHeight,
        (endLEyePoint.x - startREyePoint.x) * videoWidth,
        disty * videoHeight
      );
      // ///////////////////////////ROTATE///////
      const leftEye = landmarks[33];
      const rightEye = landmarks[263];

      const angle = Math.atan2(rightEye.y - leftEye.y, rightEye.x - leftEye.x);

      clear();

      canvasCtx.translate(
        landmarks[70].x * videoWidth, //TODO:try to calculate the distance between two points to decrease the difference
        landmarks[70].y * videoHeight
      );
      canvasCtx.rotate(angle);

      canvasCtx.save();
      canvasCtx.drawImage(
        imageRef.current,
        (-startREyePoint.x * videoWidth) / (disty * videoHeight) - 70,
        (-startREyePoint.y * videoHeight) / (disty * videoHeight) - 90,
        distx * videoWidth * 2 + widthtUp,
        disY * videoHeight * 2 + heightUp
      );

      canvasCtx.restore();
    }

    if (results.multiFaceLandmarks) {
      for (const landmarks of results.multiFaceLandmarks) {
        let nosePoint = landmarks[10];
        let middleREyepoin = landmarks[133] - landmarks[33] / 2;
        let startREyePoint = landmarks[234];
        let endLEyePoint = landmarks[454];
        let REyeHeighttop = landmarks[53];
        let REyeHeightbottom = landmarks[118];
        const ydistREye = landmarks[301];
        getDistanceY(endLEyePoint);
        make_base(
          startREyePoint,
          endLEyePoint,
          REyeHeighttop,
          REyeHeightbottom,
          nosePoint,
          landmarks,
          ydistREye
        );
        // if (landmarks[168].z < -0.02) {
        //   make_base(landmarks[9], true);
        // }
      }
    }
    canvasCtx.restore();
  }
  // }

  // setInterval(())
  useEffect(() => {
    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.onResults(onResults);

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          await faceMesh.send({ image: webcamRef?.current?.video });
        },
        width: 640,
        height: 480,
      });
      camera.start();
    }
  }, [history.location.state, widthtUp, heightUp]);
  return windowWidth <= 480 ? (
    <div>
      <NavBarScnd
        isScrolling={false}
        isTitle={true}
        title="."
        isBGColor={true}
        bgColortxt="red"
        isBack={false}
      />
      <button id="ar-back-btn">
        <a href="/">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.452"
            height="18.823"
            viewBox="0 0 18.452 15.823">
            <g
              id="Group_712"
              data-name="Group 712"
              transform="translate(1045.156 2696.584) rotate(180)">
              <path
                id="Path_534"
                data-name="Path 534"
                d="M1045.156,2688.888a1.748,1.748,0,0,1-.411.725,1.383,1.383,0,0,1-.871.383c-.1.007-.2.007-.306.007h-12.723a.728.728,0,0,0,.1.073,3.147,3.147,0,0,1,1.036.732q1.714,1.726,3.434,3.446a1.369,1.369,0,0,1,.415,1.456,1.279,1.279,0,0,1-1.984.616,2.5,2.5,0,0,1-.243-.214q-3.218-3.216-6.435-6.434a1.322,1.322,0,0,1-.279-1.685,1.694,1.694,0,0,1,.27-.335q3.227-3.233,6.459-6.461a1.317,1.317,0,0,1,1.988.034,1.351,1.351,0,0,1-.01,1.681c-.068.083-.144.161-.22.238q-1.464,1.466-2.931,2.93a4.421,4.421,0,0,1-1.57,1.236l.013.046h.2q6.3,0,12.6,0a1.335,1.335,0,0,1,1.171.508,3.6,3.6,0,0,1,.3.624Z"
                fill="#fff"
              />
            </g>
          </svg>
        </a>
      </button>
      <center>
        <button onClick={repositionHandler} id="reposition-btn">
          Reposition
        </button>
        <div className="App">
          <Webcam
            ref={webcamRef}
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              zindex: 9,
              width: "90%",
              height: "30%",
            }}
          />{" "}
          <canvas
            onTouchStart={reposition && onTouchHandler}
            onTouchEnd={reposition && Touchremove}
            ref={canvasRef}
            className="output_canvas"
            style={
              !reposition
                ? {
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    zindex: 9,
                    width: "90%",
                    height: "30%",
                  }
                : {
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: "5%",
                    textAlign: "center",
                    zindex: 9,
                    width: "90%",
                    height: "30%",
                  }
            }></canvas>
          <img
            id="source"
            src={history.location.state}
            ref={imageRef}
            style={{ display: "none" }}
            width="300"
            height="234"
          />
          <div className="controls">
            <button id="width-dec" onClick={widthdown}>
              -
            </button>
            <button id="width-inc" onClick={widthup}>
              +
            </button>

            <button id="height-inc" onClick={heightup}>
              +
            </button>

            <button id="height-dec" onClick={heightdown}>
              -
            </button>
            <p>RESIZE</p>
          </div>
        </div>
      </center>
      <BottomBar />
    </div>
  ) : (
    <div>
      <div style={{ position: "absolute", top: "0" }}>
        <Label ChangeZindex={ChangeZindex} />
      </div>
      <BsNavBar Zindex={Zindex} withSearch={false} isRefresh={true} />
      <button>
        <a href="/">Go Back</a>
      </button>
      <center>
        <button onClick={repositionHandler} id="reposition-btn">
          Reposition
        </button>
        <div className="App">
          <Webcam
            ref={webcamRef}
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              zindex: 9,
              width: 640,
              height: 480,
            }}
          />
          <canvas
            onMouseDown={reposition && onMouseDownHandler}
            onMouseUp={reposition && remove}
            onTouchStart={reposition && onTouchHandler}
            onTouchEnd={reposition && Touchremove}
            ref={canvasRef}
            className="output_canvas"
            style={
              !reposition
                ? {
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    zindex: 9,
                    width: 640,
                    height: 480,
                  }
                : {
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: "25%",

                    textAlign: "center",
                    zindex: 9,
                    width: 640,
                    height: 480,
                  }
            }></canvas>
          <img
            id="source"
            src={history.location.state}
            ref={imageRef}
            style={{ display: "none" }}
            width="300"
            height="234"
          />
          <div className="controls">
            <button id="width-dec" onClick={widthdown}>
              -
            </button>
            <button id="width-inc" onClick={widthup}>
              +
            </button>

            <button id="height-inc" onClick={heightup}>
              +
            </button>

            <button id="height-dec" onClick={heightdown}>
              -
            </button>
            <p>RESIZE</p>
          </div>
        </div>
      </center>
    </div>
  );
}

export default Glasses;

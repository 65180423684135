import firebase from "firebase/app";
import "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCoql8IeZtbhBfnBAbyM7mKRuhGt7lw1CQ",
  authDomain: "prixmap-150e6.firebaseapp.com",
  projectId: "prixmap-150e6",
  storageBucket: "prixmap-150e6.appspot.com",
  messagingSenderId: "382591990324",
  appId: "1:382591990324:web:aa8654d2db7e05270e2861",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// exports

export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import LocalSearch from "../../pages/forms/LocalSearch";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./BSNavBar.css";
import { Badge } from "antd";

function BSNavBar({
  ChangeZindex,
  Zindex,
  keyword,
  setKeyword,
  withSearch = true,
  isRefresh = false,
}) {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const { user, cart, clickedState } = useSelector((state) => ({ ...state }));
  const [windowWidth, setWindowWidth] = useState("480");
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
  }, [windowWidth]);

  return (
    <div className="BSNavBar-container" style={{ zIndex: Zindex }}>
      <div className="bs-nav-menu-icons">
        {/* Home */}
        {isRefresh ? (
          <a href="/" id="bs-home-link">
            <svg
              id="Iconly_Bold_Home"
              data-name="Iconly Bold Home"
              xmlns="http://www.w3.org/2000/svg"
              width="34.106"
              height="35.901"
              viewBox="0 0 34.106 35.901">
              <g id="Home-3" transform="translate(0 0)">
                <path
                  id="Home-4"
                  d="M560.056,271.864v-5.488a2.548,2.548,0,0,1,2.553-2.542h5.16a2.565,2.565,0,0,1,1.807.743,2.527,2.527,0,0,1,.749,1.8v5.489a2.174,2.174,0,0,0,.639,1.556,2.207,2.207,0,0,0,1.564.646h3.519a6.207,6.207,0,0,0,4.385-1.795,6.115,6.115,0,0,0,1.819-4.346V252.287a4.437,4.437,0,0,0-1.615-3.41l-11.966-9.5a5.565,5.565,0,0,0-7.089.127l-11.7,9.367a4.441,4.441,0,0,0-1.736,3.414V267.9a6.184,6.184,0,0,0,6.2,6.164h3.441a2.21,2.21,0,0,0,2.219-2.187Z"
                  transform="translate(-548.146 -238.166)"
                  fill={clickedState[0] === "3" ? "#4e3389" : "#b5b5b5"}
                />
              </g>
            </svg>
            <p id="bs-home-title">{t("Home.1")}</p>
          </a>
        ) : (
          <div
            onClick={() => {
              dispatch({ type: "CLICKED", payload: "3" });

              history.push("/");
            }}
            id="bs-home-link">
            <svg
              id="Iconly_Bold_Home"
              data-name="Iconly Bold Home"
              xmlns="http://www.w3.org/2000/svg"
              width="34.106"
              height="35.901"
              viewBox="0 0 34.106 35.901">
              <g id="Home-3" transform="translate(0 0)">
                <path
                  id="Home-4"
                  d="M560.056,271.864v-5.488a2.548,2.548,0,0,1,2.553-2.542h5.16a2.565,2.565,0,0,1,1.807.743,2.527,2.527,0,0,1,.749,1.8v5.489a2.174,2.174,0,0,0,.639,1.556,2.207,2.207,0,0,0,1.564.646h3.519a6.207,6.207,0,0,0,4.385-1.795,6.115,6.115,0,0,0,1.819-4.346V252.287a4.437,4.437,0,0,0-1.615-3.41l-11.966-9.5a5.565,5.565,0,0,0-7.089.127l-11.7,9.367a4.441,4.441,0,0,0-1.736,3.414V267.9a6.184,6.184,0,0,0,6.2,6.164h3.441a2.21,2.21,0,0,0,2.219-2.187Z"
                  transform="translate(-548.146 -238.166)"
                  fill={clickedState[0] === "3" ? "#4e3389" : "#b5b5b5"}
                />
              </g>
            </svg>
            <p id="bs-home-title">{t("Home.1")}</p>
          </div>
        )}
        <div id="bs-home-v-line"></div>
        {/* Search */}
        <div
          id="bs-home-link"
          onClick={() => {
            dispatch({ type: "CLICKED", payload: "2" });

            history.push("/mapSearch");
          }}>
          <svg
            id="search-icon"
            data-name="Group 719"
            xmlns="http://www.w3.org/2000/svg"
            width="36.205"
            height="36.217"
            viewBox="0 0 36.205 36.217">
            <path
              id="Path_591"
              data-name="Path 591"
              d="M1288.129,1636.327c.116-.849.2-1.7.356-2.545a17.332,17.332,0,0,1,2.05-5.5.478.478,0,0,1,.26-.2,5.573,5.573,0,0,1,1.02-.188l-1.179.949a2.823,2.823,0,0,0,1.764.711,20.273,20.273,0,0,0,3.808-.5.6.6,0,0,1,.751.336c.475.826,1,1.626,1.484,2.446a1.119,1.119,0,0,1,.148.524c.016.766,0,1.532.015,2.3a1.026,1.026,0,0,0,.176.543c.729,1.014,1.482,2.01,2.212,3.023a.5.5,0,0,0,.636.224v-1.044l.074-.012c.262.547.533,1.09.781,1.643a.577.577,0,0,0,.456.374c.784.16,1.566.33,2.341.528a1.422,1.422,0,0,1,.622.345c.652.643,1.231,1.386,2.333,1.389a12.626,12.626,0,0,1-.729,1.5,1.686,1.686,0,0,0,.049,2.134,12.958,12.958,0,0,1,1.556,4.175.8.8,0,0,1-.019.379c-.185.778-.337,1.568-.581,2.328a6.533,6.533,0,0,0-.05,2.449c.01.174.027.347.042.538l-1.346.172h-1.981a1.982,1.982,0,0,0-.273-.058,16.993,16.993,0,0,1-7.1-2.039,17.871,17.871,0,0,1-9.389-12.9c-.133-.7-.191-1.416-.284-2.125Z"
              transform="translate(-1288.129 -1619.141)"
              fill={clickedState[0] === "2" ? "#5AAD81" : "#b5b5b5"}
            />
            <path
              id="Path_592"
              data-name="Path 592"
              d="M1354.655,1611.838a3.655,3.655,0,0,1,.585-.129.68.68,0,0,0,.7-.692c.068-.51.224-1.009.354-1.562.465.19.922.362,1.36.572.074.035.111.25.088.366a.581.581,0,0,0,.5.783,1.76,1.76,0,0,1,.422.177,1.427,1.427,0,0,0,1.806-.231c-.295-.338-.59-.7-.912-1.038a12.72,12.72,0,0,0-1.142-1.107,1.013,1.013,0,0,1-.327-1.18c.7.233,1.448.161,2.113.69a4.688,4.688,0,0,1,1.628,2.676,1.49,1.49,0,0,0,.51.815c.53.449,1.026.94,1.558,1.387a9.848,9.848,0,0,0,.919.615c.265-.418.5-.754.7-1.111a.559.559,0,0,1,.509-.3c.8-.061,1.6-.138,2.394-.209a.518.518,0,0,0,.208-.028c.4-.245.582-.019.784.309a18.124,18.124,0,0,1-10.881,27.143,1.653,1.653,0,0,1-.19.019c.1-.375.176-.708.286-1.029a.638.638,0,0,1,.253-.273c.878-.594,1.765-1.175,2.639-1.775a.99.99,0,0,0,.334-.423c.23-.567.419-1.15.649-1.716a.707.707,0,0,1,.313-.339c.525-.265,1.072-.487,1.594-.758a1.15,1.15,0,0,0,.465-.435c.637-1.179,1.25-2.371,1.888-3.591-.479-.283-.927-.587-1.407-.825a8.231,8.231,0,0,1-3.043-2.513,1.007,1.007,0,0,0-.8-.429,11.972,11.972,0,0,1-1.345-.275c-.852-.179-1.7-.394-2.558-.512a2.318,2.318,0,0,0-1.054.217.674.674,0,0,1-.948-.275,1.786,1.786,0,0,0-1.4-.816c.023-.457.045-.885.067-1.308a1.338,1.338,0,0,0-1.665.748l-.362-1.531c.442-.21.862-.418,1.291-.606a.419.419,0,0,1,.294.026c.335.143.653.335,1,.446a3.868,3.868,0,0,0,.894.124.244.244,0,0,0,.179-.159c.075-.973.793-1.561,1.315-2.275a6.744,6.744,0,0,1,2.535-2.056,3.925,3.925,0,0,1,3.069-.568.615.615,0,0,0,.175,0c.292-.565.265-.767-.157-1.2-.24-.243-.465-.5-.721-.729a6.129,6.129,0,0,1-1.725-2.416.406.406,0,0,0-.48-.3,2.736,2.736,0,0,1-1.509-.342,6.984,6.984,0,0,0-1.67-.342c-.119.652-.231,1.262-.349,1.905a10.119,10.119,0,0,1-1.769-.362"
              transform="translate(-1335.969 -1604.147)"
              fill="#b5b5b5"
            />
            <path
              id="Path_593"
              data-name="Path 593"
              d="M1304.737,1599.972a17.94,17.94,0,0,1,21.484-4.825,2.788,2.788,0,0,1-1.619-.059,1.532,1.532,0,0,0-1.243.257,7.989,7.989,0,0,0-1.4,1.236.811.811,0,0,1-.465.245c-.877.1-1.757.21-2.639.255a6.063,6.063,0,0,1-1.364-.138.386.386,0,0,0-.475.227,8.577,8.577,0,0,1-.591.922.66.66,0,0,1-.409.254,18.306,18.306,0,0,1-2.259.1,5.99,5.99,0,0,1-1.432-.349.758.758,0,0,0-.728.089,4.923,4.923,0,0,1-2.584.827,18.4,18.4,0,0,0-3.923.865C1304.989,1599.916,1304.88,1599.937,1304.737,1599.972Zm17.661-5.057c-.987-.771-1.031-.776-2.041-.22-.288.159-.579.312-.862.479-.377.223-.748.457-1.133.693.587.491.665.519,1.334.44a2.852,2.852,0,0,0,1.063-.176A17.446,17.446,0,0,0,1322.4,1594.916Zm-9.642,1.433c.377.22.727.428,1.082.627a.292.292,0,0,0,.2.02c.773-.2,1.544-.4,2.344-.614-.143-.25-.288-.465-.394-.7-.114-.251-.262-.334-.518-.2a1.37,1.37,0,0,1-1.431-.053.122.122,0,0,0-.067-.013Z"
              transform="translate(-1300.518 -1593.43)"
              fill="#b5b5b5"
            />
          </svg>
          <p id="bs-home-title">{t("Search.0")}</p>
        </div>
        <div id="bs-home-v-line"></div>
        {/* Filter */}
        <div
          id="bs-home-link"
          onClick={() => {
            dispatch({ type: "CLICKED", payload: "1" });
            history.push(windowWidth <= 480 ? "/store" : "/bs-store");
          }}>
          <svg
            id="Iconly_Bold_Filter_2"
            data-name="Iconly Bold Filter 2"
            xmlns="http://www.w3.org/2000/svg"
            width="35.902"
            height="35.902"
            viewBox="0 0 35.902 35.902">
            <g id="Filter_2" data-name="Filter 2" transform="translate(0 0)">
              <path
                id="Filter_2-2"
                data-name="Filter 2-2"
                d="M862.948,455.47a1.438,1.438,0,0,1-.639-1.2v-8.644a1,1,0,0,1,.989-1.013h0l8.715.027A1,1,0,0,1,873,445.65v4.9a1.437,1.437,0,0,1-.815,1.3l-7.909,3.716a1.385,1.385,0,0,1-.579.128A1.36,1.36,0,0,1,862.948,455.47Zm9.6-14.055-9.777-.03a1.054,1.054,0,0,1-.784-.359l-10.729-11.794a4,4,0,0,1-1.03-2.693v-2.809a3.879,3.879,0,0,1,3.82-3.937h28.261a3.879,3.879,0,0,1,3.82,3.935h0v2.751a4,4,0,0,1-1.143,2.805L873.312,441.1a1.041,1.041,0,0,1-.747.316Z"
                transform="translate(-850.231 -419.794)"
                fill={clickedState[0] === "1" ? "#0F81A3" : "#b5b5b5"}
              />
            </g>
          </svg>

          <p id="bs-home-title">{t("Filter.0")}</p>
        </div>{" "}
        <div id="bs-home-v-line"></div>
        {/* Favorite */}
        <div
          id="bs-home-link"
          onClick={() => {
            dispatch({ type: "CLICKED", payload: "4" });

            user ? history.push("/user/wishlist") : history.push("/login");
          }}>
          <svg
            id="Iconly_Bold_Heart"
            data-name="Iconly Bold Heart"
            xmlns="http://www.w3.org/2000/svg"
            width="35.906"
            height="34.106"
            viewBox="0 0 35.906 34.106">
            <g id="Heart-2" transform="translate(0 0)">
              <path
                id="Heart-3"
                d="M747.152,272.9h0l-.465-.287a68.488,68.488,0,0,1-11.437-8.9,23.314,23.314,0,0,1-5.4-8.614,13.9,13.9,0,0,1,.256-9.468,10.917,10.917,0,0,1,6.8-6.347,8.077,8.077,0,0,1,1.6-.376h.215a10.292,10.292,0,0,1,1.509-.108h.2a11.287,11.287,0,0,1,3.286.592h.107a.612.612,0,0,1,.161.108,6.13,6.13,0,0,1,1.131.468l.682.3a4.756,4.756,0,0,1,.511.342c.1.075.191.138.26.179l.074.044a4.225,4.225,0,0,1,.465.3,11.166,11.166,0,0,1,6.831-2.334h.08a10.51,10.51,0,0,1,3.339.522,10.8,10.8,0,0,1,6.74,6.313,14.2,14.2,0,0,1,.279,9.464,22.955,22.955,0,0,1-5.4,8.634,69.346,69.346,0,0,1-11.365,8.9l-.45.269Zm8.808-28.509a1.454,1.454,0,0,0-1.359.975,1.473,1.473,0,0,0,.9,1.847,3.007,3.007,0,0,1,1.92,2.818v.054a1.545,1.545,0,0,0,.342,1.113,1.51,1.51,0,0,0,1.023.521,1.478,1.478,0,0,0,1.418-1.365v-.213a5.923,5.923,0,0,0-3.787-5.674A1.412,1.412,0,0,0,755.96,244.394Z"
                transform="translate(-729.139 -238.797)"
                fill={clickedState[0] === "4" ? "#9B2E44" : "#b5b5b5"}
              />
            </g>
          </svg>

          <p id="bs-home-title">{t("Favorite.0")}</p>
        </div>{" "}
        <div id="bs-home-v-line"></div>
        {/* Cart */}
        <div
          id="bs-home-link"
          onClick={() => {
            dispatch({ type: "CLICKED", payload: "5" });

            history.push("/cart");
          }}>
          <Badge
            offset={[-2, 2]}
            count={cart.length < 1 ? "0" : cart.length}
            showZero>
            <svg
              id="Iconly_Bold_Buy"
              data-name="Iconly Bold Buy"
              xmlns="http://www.w3.org/2000/svg"
              width="35.917"
              height="35.902"
              viewBox="0 0 35.917 35.902">
              <g id="Buy" transform="translate(0)">
                <path
                  id="Buy-2"
                  d="M277.039,577.8a2.718,2.718,0,1,1,2.727,2.767A2.757,2.757,0,0,1,277.039,577.8Zm-20.178,0a2.717,2.717,0,1,1,2.724,2.767A2.753,2.753,0,0,1,256.861,577.8Zm3.014-5.68a4.5,4.5,0,0,1-3.1-1.237,4.707,4.707,0,0,1-1.457-3.051l-1.651-19.964-2.708-.478a1.375,1.375,0,0,1,.259-2.725,1.691,1.691,0,0,1,.208.017l4.277.656a1.38,1.38,0,0,1,1.112,1.247l.342,4.1a1.108,1.108,0,0,0,1.093,1.027h24.224A3.032,3.032,0,0,1,285,552.941a4.04,4.04,0,0,1,.683,3.149l-1.706,12.016a4.633,4.633,0,0,1-4.537,4.012Zm10.383-12.253a1.36,1.36,0,0,0,1.346,1.373h4.967a1.359,1.359,0,0,0,1.346-1.373,1.345,1.345,0,0,0-1.316-1.373h-5a1.346,1.346,0,0,0-1.345,1.348v.026Z"
                  transform="translate(-249.845 -544.664)"
                  fill={clickedState[0] === "5" ? "#D3B245" : "#b5b5b5"}
                />
              </g>
            </svg>
          </Badge>

          <p id="bs-home-title">{t("Cart.0")}</p>
        </div>
      </div>
      <div className="bs-nav-search">
        {withSearch && (
          <LocalSearch
            backGround="#E3E3E3"
            keyword={keyword}
            setKeyword={setKeyword}
          />
        )}
      </div>
    </div>
  );
}

export default BSNavBar;

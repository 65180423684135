import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import { logoutUser } from "../../actions/user";
import { useHistory } from "react-router-dom";
import DropDown from "./util/DropDown";
import i18next from "i18next";

import "./Label.css";
import CountrySelector from "./CountrySelector";

function Label({ ChangeZindex }) {
  const [Lang, setLang] = React.useState("Ar");
  const dispatch = useDispatch();
  const { user, clickedState } = useSelector((state) => ({ ...state }));
  const { t } = useTranslation();
  const history = useHistory();
  const [userLang, setUserLang] = useState();
  const [country, setCountry] = useState("Fr");

  useEffect(() => {
    // let userLang = (navigator.language || navigator.userLanguage).split("-")[0];
    // const changedByUser = localStorage.getItem("langChanged");
    // if (!changedByUser) i18next.changeLanguage(userLang.toLowerCase());
  }, []);

  const logoutHandler = async () => {
    firebase.auth().signOut();
    dispatch(logoutUser());
    await history.push("/login");
  };
  function handleLang() {
    localStorage.setItem("langChanged", true);

    if (Lang === "Tr") setLang("Ar");
    if (Lang === "Ar") setLang("Fr");
    if (Lang === "Fr") setLang("En");
    if (Lang === "En") setLang("Tr");
    i18next.changeLanguage(Lang.toLowerCase());
  }

  return (
    <div
      className="label-container"
      style={{
        backgroundColor:
          clickedState[0] === "3"
            ? "#3B1D6F"
            : clickedState[0] === "2"
            ? "#2C4436"
            : clickedState[0] === "1"
            ? "#0F81A3"
            : clickedState[0] === "4"
            ? "#9B2E44"
            : clickedState[0] === "5"
            ? "#D3B245"
            : "#3B1D6F",
      }}>
      <p id="bs-prix">
        <a id="prix-link" href="/">
          prix
        </a>
      </p>
      <p id="bs-map">
        <a id="prix-link" href="/">
          map
        </a>
      </p>
      <div className="label-log">
        {user ? (
          <DropDown
            user={user}
            logoutHandler={logoutHandler}
            ChangeZindex={ChangeZindex}
          />
        ) : (
          <>
            <p>
              <a href="/login">Sign in</a>
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.951"
              height="25.228"
              viewBox="0 0 23.951 25.228">
              <g
                id="Iconly_Bold_Login"
                data-name="Iconly Bold Login"
                transform="translate(-371.576 -605.206)">
                <g id="Login">
                  <path
                    id="Login-2"
                    d="M383.388,630.434a5.7,5.7,0,0,1-5.784-5.588v-6.054h-5.057a.972.972,0,0,1,0-1.944h5.058V610.82a5.72,5.72,0,0,1,5.8-5.613h6.356a5.7,5.7,0,0,1,5.77,5.6v14.026a5.7,5.7,0,0,1-5.8,5.6h-6.342Zm2.292-11.641-2.084,2.018a.955.955,0,0,0-.026,1.35l.026.026a1.048,1.048,0,0,0,1.421,0l3.8-3.671a.94.94,0,0,0,.048-1.327c-.015-.016-.032-.033-.048-.048l-3.8-3.672a1.03,1.03,0,0,0-1.421,0,.954.954,0,0,0-.026,1.348l.026.027,2.084,2.005h-8.072v1.943Z"
                    fill="#69d4f8"
                  />
                </g>
              </g>
            </svg>
          </>
        )}
        <p id="label-language-option" onClick={handleLang}>
          {Lang}
        </p>
        <div style={{ marginLeft: "20px" }}>
          <CountrySelector ChangeZindex={ChangeZindex} />
        </div>
      </div>
    </div>
  );
}

export default Label;

import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import "./DropDown.css";

function DropDown({ user, logoutHandler, ChangeZindex }) {
  const { t } = useTranslation();

  // console.log(user);
  return (
    <Dropdown onToggle={(click) => ChangeZindex(click)}>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        {user?.name}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ borderRadius: "25px", width: "200px" }}>
        {/* Profile */}
        {user && user?.role === 0 && (
          <>
            <Dropdown.Item
              href="/user/profile"
              style={{
                backgroundColor: "#E8E8E8",
                borderRadius: "20px",
                width: "90%",
                height: "auto",
                margin: "auto",
                justifyContent: "start",
                display: "flex",
                textAlign: "center",
                marginBottom: "5px",
              }}>
              <svg
                style={{ marginRight: "5px" }}
                id="Iconly_Bold_Profile"
                data-name="Iconly Bold Profile"
                xmlns="http://www.w3.org/2000/svg"
                width="18.355"
                height="19.202"
                viewBox="0 0 23.355 29.202">
                <g id="Profile-2" transform="translate(0 0)">
                  <path
                    id="Profile-3"
                    d="M610.3,633.447c0-3.974,5.381-4.964,11.678-4.964,6.334,0,11.677,1.022,11.677,5s-5.379,4.965-11.677,4.965C615.641,638.447,610.3,637.425,610.3,633.447Zm3.95-16.472a7.728,7.728,0,1,1,7.731,7.725h0a7.7,7.7,0,0,1-7.729-7.669Z"
                    transform="translate(-610.296 -609.245)"
                    fill="#0f81a3"
                  />
                </g>
              </svg>

              {t("Profile.0")}
            </Dropdown.Item>
            {/* upload */}
            <Dropdown.Item
              href="/user/product"
              style={{
                backgroundColor: "#E8E8E8",
                borderRadius: "20px",
                width: "90%",
                height: "auto",
                margin: "auto",
                justifyContent: "start",
                display: "flex",
                textAlign: "center",
                marginBottom: "5px",
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.654"
                height="21.458"
                viewBox="0 0 20.654 21.458">
                <g
                  id="Iconly_Bold_Logout"
                  data-name="Iconly Bold Logout"
                  transform="translate(-605.207 452.933) rotate(-90)">
                  <g id="Logout" transform="translate(431.475 605.207)">
                    <path
                      id="Logout-2"
                      d="M436.139,625.861a4.629,4.629,0,0,1-4.664-4.585V609.8a4.64,4.64,0,0,1,4.676-4.6h5.126a4.627,4.627,0,0,1,4.655,4.586v4.946h-6.3a.8.8,0,0,0,0,1.591h6.3v4.936a4.64,4.64,0,0,1-4.676,4.6Zm12.416-6.764a.8.8,0,0,1,0-1.125l1.652-1.642h-4.275v-1.592h4.275l-1.652-1.642a.8.8,0,0,1,0-1.126.789.789,0,0,1,1.115-.021l.011.01,3.015,3.006a.792.792,0,0,1,.237.568.767.767,0,0,1-.237.558l-3.015,3.005a.788.788,0,0,1-1.114.013Z"
                      transform="translate(-431.475 -605.207)"
                      fill="#0f81a3"
                    />
                  </g>
                </g>
              </svg>

              {t("Upload.1")}
            </Dropdown.Item>
          </>
        )}
        {/* Admin */}
        {user && user?.role === 1 && (
          <>
            <Dropdown.Item
              href="/admin/dashboard"
              style={{
                backgroundColor: "#E8E8E8",
                borderRadius: "20px",
                width: "90%",
                height: "auto",
                margin: "auto",
                justifyContent: "start",
                display: "flex",
                textAlign: "center",
                marginBottom: "5px",
              }}>
              <svg
                style={{ marginRight: "5px" }}
                id="Iconly_Bold_Profile"
                data-name="Iconly Bold Profile"
                xmlns="http://www.w3.org/2000/svg"
                width="18.355"
                height="19.202"
                viewBox="0 0 23.355 29.202">
                <g id="Profile-2" transform="translate(0 0)">
                  <path
                    id="Profile-3"
                    d="M610.3,633.447c0-3.974,5.381-4.964,11.678-4.964,6.334,0,11.677,1.022,11.677,5s-5.379,4.965-11.677,4.965C615.641,638.447,610.3,637.425,610.3,633.447Zm3.95-16.472a7.728,7.728,0,1,1,7.731,7.725h0a7.7,7.7,0,0,1-7.729-7.669Z"
                    transform="translate(-610.296 -609.245)"
                    fill="#0f81a3"
                  />
                </g>
              </svg>

              {t("Profile.0")}
            </Dropdown.Item>
            {/* upload */}
            <Dropdown.Item
              href="/admin/upload-product"
              style={{
                backgroundColor: "#E8E8E8",
                borderRadius: "20px",
                width: "90%",
                height: "auto",
                margin: "auto",
                justifyContent: "start",
                display: "flex",
                textAlign: "center",
                marginBottom: "5px",
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.654"
                height="21.458"
                viewBox="0 0 20.654 21.458">
                <g
                  id="Iconly_Bold_Logout"
                  data-name="Iconly Bold Logout"
                  transform="translate(-605.207 452.933) rotate(-90)">
                  <g id="Logout" transform="translate(431.475 605.207)">
                    <path
                      id="Logout-2"
                      d="M436.139,625.861a4.629,4.629,0,0,1-4.664-4.585V609.8a4.64,4.64,0,0,1,4.676-4.6h5.126a4.627,4.627,0,0,1,4.655,4.586v4.946h-6.3a.8.8,0,0,0,0,1.591h6.3v4.936a4.64,4.64,0,0,1-4.676,4.6Zm12.416-6.764a.8.8,0,0,1,0-1.125l1.652-1.642h-4.275v-1.592h4.275l-1.652-1.642a.8.8,0,0,1,0-1.126.789.789,0,0,1,1.115-.021l.011.01,3.015,3.006a.792.792,0,0,1,.237.568.767.767,0,0,1-.237.558l-3.015,3.005a.788.788,0,0,1-1.114.013Z"
                      transform="translate(-431.475 -605.207)"
                      fill="#0f81a3"
                    />
                  </g>
                </g>
              </svg>

              {t("Upload.1")}
            </Dropdown.Item>
          </>
        )}
        {user && user?.role === 2 && (
          <>
            <Dropdown.Item
              href="/seller/dashboard"
              style={{
                backgroundColor: "#E8E8E8",
                borderRadius: "20px",
                width: "90%",
                height: "auto",
                margin: "auto",
                justifyContent: "start",
                display: "flex",
                textAlign: "center",
                marginBottom: "5px",
              }}>
              <svg
                style={{ marginRight: "5px" }}
                id="Iconly_Bold_Profile"
                data-name="Iconly Bold Profile"
                xmlns="http://www.w3.org/2000/svg"
                width="18.355"
                height="19.202"
                viewBox="0 0 23.355 29.202">
                <g id="Profile-2" transform="translate(0 0)">
                  <path
                    id="Profile-3"
                    d="M610.3,633.447c0-3.974,5.381-4.964,11.678-4.964,6.334,0,11.677,1.022,11.677,5s-5.379,4.965-11.677,4.965C615.641,638.447,610.3,637.425,610.3,633.447Zm3.95-16.472a7.728,7.728,0,1,1,7.731,7.725h0a7.7,7.7,0,0,1-7.729-7.669Z"
                    transform="translate(-610.296 -609.245)"
                    fill="#0f81a3"
                  />
                </g>
              </svg>

              {t("Profile.0")}
            </Dropdown.Item>
            {/* upload */}
            <Dropdown.Item
              href="/seller/shop-create"
              style={{
                backgroundColor: "#E8E8E8",
                borderRadius: "20px",
                width: "90%",
                height: "auto",
                margin: "auto",
                justifyContent: "start",
                display: "flex",
                textAlign: "center",
                marginBottom: "5px",
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.654"
                height="21.458"
                viewBox="0 0 20.654 21.458">
                <g
                  id="Iconly_Bold_Logout"
                  data-name="Iconly Bold Logout"
                  transform="translate(-605.207 452.933) rotate(-90)">
                  <g id="Logout" transform="translate(431.475 605.207)">
                    <path
                      id="Logout-2"
                      d="M436.139,625.861a4.629,4.629,0,0,1-4.664-4.585V609.8a4.64,4.64,0,0,1,4.676-4.6h5.126a4.627,4.627,0,0,1,4.655,4.586v4.946h-6.3a.8.8,0,0,0,0,1.591h6.3v4.936a4.64,4.64,0,0,1-4.676,4.6Zm12.416-6.764a.8.8,0,0,1,0-1.125l1.652-1.642h-4.275v-1.592h4.275l-1.652-1.642a.8.8,0,0,1,0-1.126.789.789,0,0,1,1.115-.021l.011.01,3.015,3.006a.792.792,0,0,1,.237.568.767.767,0,0,1-.237.558l-3.015,3.005a.788.788,0,0,1-1.114.013Z"
                      transform="translate(-431.475 -605.207)"
                      fill="#0f81a3"
                    />
                  </g>
                </g>
              </svg>

              {t("Upload.1")}
            </Dropdown.Item>
          </>
        )}
        {/* Contact */}
        <Dropdown.Item
          href="/contact-us"
          style={{
            backgroundColor: "#E8E8E8",
            borderRadius: "20px",
            width: "90%",
            height: "auto",
            margin: "auto",
            justifyContent: "start",
            display: "flex",
            textAlign: "center",
            marginBottom: "5px",
          }}>
          <svg
            style={{ marginRight: "5px" }}
            id="Iconly_Bold_Info_Circle"
            data-name="Iconly Bold Info Circle"
            xmlns="http://www.w3.org/2000/svg"
            width="22.406"
            height="22.406"
            viewBox="0 0 29.406 29.406">
            <g
              id="Info_Circle"
              data-name="Info Circle"
              transform="translate(0 0)">
              <path
                id="Info_Circle-2"
                data-name="Info Circle-2"
                d="M325.092,574.069a14.7,14.7,0,1,1,10.4-4.306A14.7,14.7,0,0,1,325.092,574.069Zm0-10.393a1.286,1.286,0,1,0,.016,0Zm0-11.175A1.312,1.312,0,0,0,323.8,553.8v6.5a1.287,1.287,0,1,0,2.574,0v-6.5a1.287,1.287,0,0,0-1.28-1.294Z"
                transform="translate(-310.392 -544.663)"
                fill="#0f81a3"
              />
            </g>
          </svg>

          {t("Contact Us.0")}
        </Dropdown.Item>
        {/* Language */}
        {/* <Dropdown.Item
          style={{
            backgroundColor: "#E8E8E8",
            borderRadius: "20px",
            width: "90%",
            height: "auto",
            margin: "auto",
            justifyContent: "start",
            display: "flex",
            textAlign: "center",
            marginBottom: "5px",
          }}>
          <svg
            style={{ marginRight: "5px" }}
            id="Iconly_Bold_Setting"
            data-name="Iconly Bold Setting"
            xmlns="http://www.w3.org/2000/svg"
            width="24.785"
            height="24.214"
            viewBox="0 0 26.785 28.214">
            <g id="Setting-2" transform="translate(0 0)">
              <path
                id="Setting-3"
                d="M380.9,387.464h-1.965a2.915,2.915,0,0,1-3-2.831v-.062A2.681,2.681,0,0,0,373.286,382a2.233,2.233,0,0,0-1.269.324,3.04,3.04,0,0,1-1.529.424,2.993,2.993,0,0,1-2.562-1.467l-.988-1.692a2.824,2.824,0,0,1-.029-2.892,2.969,2.969,0,0,1,1.152-1.113,2.331,2.331,0,0,0,.909-.9,2.51,2.51,0,0,0,.267-1.925,2.586,2.586,0,0,0-1.211-1.6,2.885,2.885,0,0,1-1.106-3.927l.038-.066.967-1.664a3,3,0,0,1,4.065-1.072,2.68,2.68,0,0,0,1.27.316,2.763,2.763,0,0,0,2.346-1.289,2.161,2.161,0,0,0,.333-1.241,2.52,2.52,0,0,1,.4-1.5,3.1,3.1,0,0,1,2.55-1.467h2.033a3.039,3.039,0,0,1,2.567,1.467,2.5,2.5,0,0,1,.388,1.5,2.18,2.18,0,0,0,.332,1.241,2.772,2.772,0,0,0,2.359,1.289,2.706,2.706,0,0,0,1.269-.316,2.977,2.977,0,0,1,4.051,1.072l.965,1.664a2.861,2.861,0,0,1-.955,3.93q-.055.034-.111.064a2.577,2.577,0,0,0-1.2,1.606,2.5,2.5,0,0,0,.282,1.92,2.217,2.217,0,0,0,.895.9,3.246,3.246,0,0,1,1.168,1.113,2.868,2.868,0,0,1-.028,2.892l-1.009,1.692a2.962,2.962,0,0,1-4.053,1.06l-.028-.017a2.291,2.291,0,0,0-1.269-.324,2.679,2.679,0,0,0-2.667,2.568,2.907,2.907,0,0,1-2.922,2.892Zm-.97-18.084a4.048,4.048,0,0,0-4.091,3.991,3.9,3.9,0,0,0,1.2,2.822,4.127,4.127,0,0,0,2.9,1.158,3.981,3.981,0,0,0,2.872-6.806A4.054,4.054,0,0,0,379.934,369.38Z"
                transform="translate(-366.526 -359.251)"
                fill="#0f81a3"
              />
            </g>
          </svg>
          Language
        </Dropdown.Item> */}
        {/* sign out */}
        <Dropdown.Item
          style={{
            backgroundColor: "#E8E8E8",
            borderRadius: "20px",
            width: "90%",
            height: "auto",
            margin: "auto",
            justifyContent: "start",
            display: "flex",
            textAlign: "center",
            marginBottom: "5px",
          }}
          onClick={logoutHandler}>
          <svg
            style={{ marginRight: "5px", marginLeft: "4px" }}
            id="Iconly_Bold_Logout"
            data-name="Iconly Bold Logout"
            xmlns="http://www.w3.org/2000/svg"
            width="22.479"
            height="22.413"
            viewBox="0 0 28.479 27.413">
            <g id="Logout" transform="translate(0 0)">
              <path
                id="Logout-2"
                d="M437.666,632.619a6.143,6.143,0,0,1-6.191-6.085V611.306a6.158,6.158,0,0,1,6.206-6.1h6.8a6.141,6.141,0,0,1,6.179,6.086v6.564H442.3a1.056,1.056,0,0,0,0,2.111h8.361v6.551a6.158,6.158,0,0,1-6.206,6.1Zm16.479-8.977a1.058,1.058,0,0,1,0-1.493l2.193-2.18h-5.674v-2.112h5.674l-2.193-2.179a1.06,1.06,0,0,1,0-1.494,1.047,1.047,0,0,1,1.48-.027l.014.013,4,3.989a1.051,1.051,0,0,1,.314.754,1.018,1.018,0,0,1-.314.74l-4,3.989a1.045,1.045,0,0,1-1.478.017Z"
                transform="translate(-431.475 -605.207)"
                fill="#0f81a3"
              />
            </g>
          </svg>
          {t("Sign out.1")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropDown;

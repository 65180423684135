import { LOGGED_IN_USER, REGISTER_USER, LOGOUT_USER } from "../actions/types";

export default function user(state = null, action) {
  switch (action.type) {
    case LOGGED_IN_USER:
      return action.payload;
    case REGISTER_USER:
      return { ...state, register: action.payload };
    // case LOGIN_USER:
    //   return { ...state, loginSucces: action.payload };
    // case GLOGIN_USER:
    //   return { ...state, loginSucces: action.payload };
    // case FLOGIN_USER:
    //   return { ...state, loginSucces: action.payload };
    // case AUTH_USER:
    //   return { ...state, userData: action.payload };
    case LOGOUT_USER:
      return action.payload;
    // case ADD_TO_CART_USER:
    //   return {
    //     ...state,
    //     userData: {
    //       ...state.userData,
    //       cart: action.payload,
    //     },
    //   };
    // case EDIT_PRODUCT:
    //   return {
    //     ...state,
    //     productEdit: action.payload,
    //   };
    // case GET_CART_ITEMS_USER:
    //   console.log("get_cart_items_user", action.payload);
    //   return {
    //     ...state,
    //     cartDetail: action.payload,
    //   };
    // case REMOVE_CART_ITEM_USER:
    //   return {
    //     ...state,
    //     cartDetail: action.payload.cartDetail,
    //     userData: {
    //       ...state.userData,
    //       cart: action.payload.cart,
    //     },
    //   };
    // case ON_SUCCESS_BUY_USER:
    //   return {
    //     ...state,
    //     userData: {
    //       ...state.userData,
    //       cart: action.payload.cart,
    //     },
    //     cartDetail: action.payload.cartDetail,
    //   };

    default:
      return state;
  }
}

export const Continents = [
  { key: 0, value: "Adrar" },
  { key: 1, value: "Alger" },
  { key: 2, value: "Annaba" },
  { key: 3, value: "Aïn Defla" },
  { key: 4, value: "Aïn Témouchent" },
  { key: 5, value: "Batna" },
  { key: 6, value: "Biskra" },
  { key: 7, value: "Blida" },
  { key: 8, value: "Bordj Badji Mokhtar" },
  { key: 9, value: "Bordj Bou Arréridj" },
  { key: 10, value: "Bouira" },
  { key: 11, value: "Boumerdès" },
  { key: 12, value: "Béchar" },
  { key: 13, value: "Béjaïa" },
  { key: 14, value: "Béni Abbès" },
  { key: 15, value: "Chlef" },
  { key: 16, value: "Constantine" },
  { key: 17, value: "Djanet" },
  { key: 18, value: "Djelfa" },
  { key: 19, value: "El Bayadh" },
  { key: 20, value: "El M'Ghair" },
  { key: 21, value: "El Menia" },
  { key: 22, value: "El Oued" },
  { key: 23, value: "El Tarf" },
  { key: 24, value: "Ghardaïa" },
  { key: 25, value: "Guelma" },
  { key: 26, value: "Illizi" },
  { key: 27, value: "In Guezzam" },
  { key: 28, value: "In Salah" },
  { key: 29, value: "Jijel" },
  { key: 30, value: "Khenchela" },
  { key: 31, value: "Laghouat" },
  { key: 32, value: "M'Sila" },
  { key: 33, value: "Mascara" },
  { key: 34, value: "Mila" },
  { key: 35, value: "Mostaganem" },
  { key: 36, value: "Médéa" },
  { key: 37, value: "Naâma" },
  { key: 38, value: "Oran" },
  { key: 39, value: "Ouargla" },
  { key: 40, value: "Ouled Djellal" },
  { key: 41, value: "Oum El Bouaghi" },
  { key: 42, value: "Relizane" },
  { key: 43, value: "Saïda" },
  { key: 44, value: "Sidi Bel Abbès" },
  { key: 45, value: "Skikda" },
  { key: 46, value: "Souk Ahras" },
  { key: 47, value: "Sétif" },
  { key: 48, value: "Tamanghasset" },
  { key: 49, value: "Tiaret" },
  { key: 50, value: "Timimoune" },
  { key: 51, value: "Tindouf" },
  { key: 52, value: "Tipaza" },
  { key: 53, value: "Tissemsilt" },
  { key: 54, value: "Tizi Ouzou" },
  { key: 55, value: "Tlemcen" },
  { key: 56, value: "Touggourt" },
  { key: 57, value: "Tébessa" },
];

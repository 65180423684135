import React from "react";
import { Input } from "antd";

const { Search } = Input;

const LocalSearch = (props, { keyword, setKeyword, backGround = "white" }) => {
  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  return (
    <input
      type="search"
      placeholder="Search"
      onChange={handleSearchChange}
      enterbutton={props.enterButtonValue}
      style={{
        border: " 0px solid black",
        borderRadius: "20px",
        backgroundColor: backGround,
        width: "80%",
        padding: "6px",
        marginRight: "5px",
      }}
    />
  );
};

export default LocalSearch;

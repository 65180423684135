import React from "react";
import france from "./france.json";
const Test = () => {
  const arrayOfProvince = france.objects.collection.geometries.map(
    (d, index) => {
      return { key: index + 58, value: d.properties.name.toString() };
    }
  );
  console.log(arrayOfProvince);
  return <div>Test</div>;
};

export default Test;

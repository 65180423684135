export const ConfirmReducer = (
  state = {
    openAlert: false,
    typeOfAlert: "",
  },
  action
) => {
  switch (action.type) {
    case "OPEN_ALERT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

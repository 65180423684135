import React, { Suspense, useEffect, useState, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { currentUser } from "./functions/auth";
import { Spin } from "antd";
import StartPage from "./StartPage";
import { AllprodsOfCities } from "./functions/product";
import { Continents } from "./pages/utils/Continents";
import ConfirmModal from "./components/modal/ConfirmModal";
import { Helmet } from "react-helmet";
import Locations from "./components/locations/Locations";

import Glasses from "./AR/Glasses";
import Hijab from "./AR/Hijab";
import Test from "./map/Test";
import { FrContinents } from "./pages/utils/FrContinents";
import ArSubscription from "./components/ArSubscription/ArSubscription";
import TryOnPackage from "./pages/BsPages/TryOnPackage/TryOnPackage";
import BsGlasses from "./AR/BsGlasses";
import { IqContinents } from "./pages/utils/IqContents.js";
import { TrContinents } from "./pages/utils/TrContinents.js";

// import LandingPage from "./views/LandingPage/LandingPage.js";
// import UploadProductPage from "./pages/uploadProduct/UploadProductPage";
// import DetailProductPage from "./views/DetailProductPage/DetailProductPage";
// import ToCityProducts from "./views/LandingPage/map/ToCityProducts";
// // import StartingPage from "./views/StartingPage";
// import RegisterComplete from "./views/RegisterPage/RegisterComplete";
// import MyItems from "./views/LandingPage/MyItems";
// import EditProductByUser from "./views/LandingPage/EditProductByUser";
// // import { LOGGED_IN_USER } from "../_actions/types";
///////////////////////////////////////////////////////////////////////
// import Home from "./Home";
// import ForgotPassword from "./pages/auth/ForgotPassword";
// import Login from "./pages/auth/Login";
// import Register from "./pages/auth/Register";
// import Footer from "./pages/footer/Footer";
// import NavBar from "./pages/NavBar/NavBar";
// import Shop from "./pages/Shop";
// import SideDrawer from "./components/drawer/SideDrawer";
// import CartPage from "./components/cards/CartPage";
// import HistoryPage from "./components/user/HistoryPage";
// import RegisterComplete from "./pages/auth/RegisterComplete";
// import UserRoute from "./components/routes/UserRoute";
// import AdminRoute from "./components/routes/AdminRoute";
// import Password from "./components/user/Password";
// import Wishlist from "./components/user/Wishlist";
// import ProductCreate from "./pages/product/ProductCreate";
// import AdminDashboard from "./components/admin/AdminDashboard";
// import CategoryCreate from "./components/admin/category/CategoryCreate";
// import CategoryUpdate from "./components/admin/category/CategoryUpdate";
// import SubCreate from "./components/admin/sub/SubCreate";
// import SubUpdate from "./components/admin/sub/SubUpdate";
// import AllProducts from "./components/admin/products/AllProducts";
// import ProductUpdate from "./components/admin/products/ProductUpdate";
// import MyItems from "./components/user/MyItems";
// import HomeMap from "./map/HomeMap";
// import ToCityProducts from "./map/ToCityProducts";
// import MyItemUpdate from "./components/user/MyItemUpdate";
// import SellerRoute from "./components/routes/SellerRoute";
// import SellerDashboard from "./components/seller/SellerDashboard";
// import SellerCatCreate from "./components/seller/category/SellerCatCreate";
// import SellerCatUpdate from "./components/seller/category/SellerCatUpdate";
// import SellerSubCreate from "./components/seller/sub/SellerSubCreate";
// import SellerSubUpdate from "./components/seller/sub/SellerSubUpdate";
// import ShopCreate from "./components/seller/shop/ShopCreate";
// import MyShops from "./components/seller/shop/MyShops";
// import SellerItems from "./components/seller/SellerItems";
// import SellerShops from "./components/seller/shop/SellerShops";
// import CreateCouponPage from "./components/admin/coupon/CreateCouponPage";
// import StripeCallback from "../src/components/stripe/StripeCallback";
// import ShopUpdate from "./components/seller/shop/ShopUpdate";
// import AdminShopCreate from "./components/admin/shop/AdminShopCreate";
// import AdminShopUpdate from "./components/admin/shop/AdminShopUpdate";
// import UsersOption from "./components/admin/userOption/UsersOption";
// import DetailedProduct from "./pages/product/DetailedProduct";
// import CategoryHome from "./pages/category/CategoryHome";
// import SubHome from "./pages/sub/SubHome";
// import Store from "./pages/Store";
// import Checkout from "./pages/Checkout";
// import Payment from "./pages/Payment";
// import ShopDashboard from "./components/seller/ShopDashboard";
// import ContactUs from "./pages/ContactUs";
// import Dashboard from "./components/user/Dashboard";
//////////Lazy import //////////////////////////////////////
const Home = lazy(() => import("./Home/Home.js"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const Footer = lazy(() => import("./pages/footer/Footer"));
const NavBar = lazy(() => import("./pages/NavBar/NavBar"));
const BottomBar = lazy(() => import("./pages/NavBar/BottomBar"));
const Shop = lazy(() => import("./pages/Shop"));
const SideDrawer = lazy(() => import("./components/drawer/SideDrawer"));
const CartPage = lazy(() => import("./components/cards/CartPage"));
const HistoryPage = lazy(() => import("./components/user/HistoryPage"));
const RegisterComplete = lazy(() => import("./pages/auth/RegisterComplete"));
const UserRoute = lazy(() => import("./components/routes/UserRoute"));
const AdminRoute = lazy(() => import("./components/routes/AdminRoute"));
const Password = lazy(() => import("./components/user/Password"));
const Wishlist = lazy(() => import("./components/user/Wishlist"));
const ProductCreate = lazy(() => import("./pages/product/ProductCreate"));
const AdminDashboard = lazy(() => import("./components/admin/AdminDashboard"));
const CategoryCreate = lazy(() =>
  import("./components/admin/category/CategoryCreate")
);
const CategoryUpdate = lazy(() =>
  import("./components/admin/category/CategoryUpdate")
);
const SubCreate = lazy(() => import("./components/admin/sub/SubCreate"));
const SubUpdate = lazy(() => import("./components/admin/sub/SubUpdate"));
const AllProducts = lazy(() =>
  import("./components/admin/products/AllProducts")
);
const ProductUpdate = lazy(() =>
  import("./components/admin/products/ProductUpdate")
);
const MyItems = lazy(() => import("./components/user/MyItems"));
const HomeMap = lazy(() => import("./map/HomeMap"));
const ToCityProducts = lazy(() => import("./map/ToCityProducts"));
const MyItemUpdate = lazy(() => import("./components/user/MyItemUpdate"));
const SellerRoute = lazy(() => import("./components/routes/SellerRoute"));
const SellerDashboard = lazy(() =>
  import("./components/seller/SellerDashboard")
);
const SellerCatCreate = lazy(() =>
  import("./components/seller/category/SellerCatCreate")
);
const SellerCatUpdate = lazy(() =>
  import("./components/seller/category/SellerCatUpdate")
);
const SellerSubCreate = lazy(() =>
  import("./components/seller/sub/SellerSubCreate")
);
const SellerSubUpdate = lazy(() =>
  import("./components/seller/sub/SellerSubUpdate")
);
const ShopCreate = lazy(() => import("./components/seller/shop/ShopCreate"));
const MyShops = lazy(() => import("./components/seller/shop/MyShops"));
const SellerItems = lazy(() => import("./components/seller/SellerItems"));
const SellerShops = lazy(() => import("./components/seller/shop/SellerShops"));
const CreateCouponPage = lazy(() =>
  import("./components/admin/coupon/CreateCouponPage")
);
const StripeCallback = lazy(() =>
  import("../src/components/stripe/StripeCallback")
);
const ShopUpdate = lazy(() => import("./components/seller/shop/ShopUpdate"));
const AdminShopCreate = lazy(() =>
  import("./components/admin/shop/AdminShopCreate")
);
const AdminShopUpdate = lazy(() =>
  import("./components/admin/shop/AdminShopUpdate")
);
const UsersOption = lazy(() =>
  import("./components/admin/userOption/UsersOption")
);
const DetailedProduct = lazy(() => import("./pages/product/DetailedProduct"));
const CategoryHome = lazy(() => import("./pages/category/CategoryHome"));
const AllCategories = lazy(() => import("./pages/category/AllCategories"));

const SubHome = lazy(() => import("./pages/sub/SubHome"));
const Store = lazy(() => import("./pages/Store"));
const BsStore = lazy(() => import("./pages/BsPages/Store/BsStore"));

const Checkout = lazy(() => import("./pages/Checkout"));
const Payment = lazy(() => import("./pages/Payment"));
const ShopDashboard = lazy(() => import("./components/seller/ShopDashboard"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Dashboard = lazy(() => import("./components/user/Dashboard"));
const UserProfile = lazy(() => import("./pages/UserProfile"));

function App() {
  const dispatch = useDispatch();

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () =>
      navigator.serviceWorker
        .register("./serviceworker.js")
        .then((reg) => {
          // console.log("Success");
        })
        .catch((err) => console.log("Error", err))
    );
  }

  const [region, setRegion] = useState("");
  const updateRegion = (countryData) => {
    const allowedCountries = ["TR", "FR", "IQ", "DZ"]; // Replace with your list of countries
    const country = allowedCountries.includes(countryData.country)
      ? countryData.country
      : "IQ";
    localStorage.setItem("region", JSON.stringify({ ...countryData, country }));
    // console.log("Updated region:", country);
    setRegion(country);
  };

  const fetchRegionData = () => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((data) => {
        // console.log("Fetched region data:", data);
        updateRegion(data);
      })
      .catch((err) => {
        console.log("This is an error", err);
      });
  };

  useEffect(() => {
    const storedRegion = localStorage.getItem("region");

    if (storedRegion !== null) {
      const localStorageCountry = JSON.parse(storedRegion);
      // console.log("LocalStorageCountry", localStorageCountry);

      if (!localStorageCountry.changedByUser) {
        navigator.geolocation.getCurrentPosition((pos) => {
          fetchRegionData();
        });
      }
    } else {
      navigator.geolocation.getCurrentPosition((pos) => {
        fetchRegionData();
      });
    }
  }, []);
  useEffect(() => {
    AllprodsOfCities(
      region === "FR"
        ? FrContinents
        : region === "IQ"
        ? IqContinents
        : region === "TR"
        ? TrContinents
        : Continents
    ).then((res) => {
      dispatch({ type: "CITY_PRODUCT_COUNT", payload: [res.data] });
      // console.log(res.data);
    });
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();

        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
                stripe_seller: res.data.stripe_seller,
                ARactivate: res.data.ARactivate,
                ARactivateAt: res.data.ARactivateAt,
                ArSubDuration: res.data.ArSubDuration,
              },
            });
          })
          .catch((err) => console.log(err));
      }
    });
    // clean up

    return () => unsubscribe();
  }, [dispatch]);

  return (
    // <div className="horo" style={{}}>
    <div>
      {/* add helmet here... */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prixmap</title>
        <meta name="description" content="Online shopping" />
        <meta name="description" content="التسوق عبر الإنترنت" />
        <meta
          name="keywords"
          content="prixmap,shopping ,new,used,shopping app,algeria,prixmap algeria,prixmap france
          ,map,data visualization,ar,Buy clothes online,Best deals on electronics,Fashion accessories online,
          Affordable home decor"
        />
        <meta
          name="keywords"
          content="التسوق عبر الإنترنت,شراء الملابس عبر الإنترنت,أفضل صفقات الإلكترونيات
,إكسسوارات الموضة عبر الإنترنت
,ديكور المنزل بأسعار معقولة
,تسوق منتجات الجمال
,خصم على الأحذية عبر الإنترنت
,أفضل هواتف محمولة تقييمًا"
        />
      </Helmet>
      <Suspense
        fallback={
          <div>
            <StartPage />
          </div>
        }>
        {/* <NavBar /> */}

        <SideDrawer />
        {/* <ConfirmModal /> */}

        <div
          style={{
            paddingTop: "75px",
            minHeight: "calc(100vh - 80px)",
            width: "100%",
          }}>
          <Switch>
            {/* <Route exact path="/" component={Auth(StartingPage, null)} /> */}
            {region !== null && <Route exact path="/" component={Home} />}
            <Route exact path="/login" component={Login} />

            <Route exact path="/register" component={Register} />

            <Route
              exact
              path="/register/complete"
              component={RegisterComplete}
            />
            <Route exact path="/shop/:id" component={Shop} />
            <Route
              exact
              path="/category-with-prods/:slug"
              component={CategoryHome}
            />
            <Route exact path="/sub-with-prods/:slug" component={SubHome} />
            <Route exact path="/store" component={Store} />
            <Route exact path="/bs-store" component={BsStore} />
            <Route exact path="/bsglasses" component={BsGlasses} />

            {/* <Route exact path="/product/upload" component={UploadProductPage} /> */}
            {/* <Route
              exact
              path="/product/updateItem/:id"
              component={EditProductByUser}
            /> */}
            {/* <Route
              exact
              path="/product/:productId"
              component={DetailProductPage}
            /> */}
            <Route exact path="/cart" component={CartPage} />
            {/* <Route exact path="/:id" component={Locations} /> */}
            <Route exact path="/glasses" component={Glasses} />
            <Route exact path="/hijab" component={Hijab} />

            {/* <Route exact path="/ToCityProducts" component={ToCityProducts} /> */}
            <Route exact path="/forgot/password" component={ForgotPassword} />
            <Route exact path="/mapSearch" component={HomeMap} />
            <Route exact path="/toCityProds/:id" component={ToCityProducts} />
            <Route exact path="/test" component={Test} />

            <Route exact path="/product/:id" component={DetailedProduct} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/categories" component={AllCategories} />
            <Route exact path="/arsubscription" component={ArSubscription} />
            <UserRoute exact path="/user/history" component={HistoryPage} />
            <UserRoute exact path="/user/password" component={Password} />
            <UserRoute exact path="/user/wishlist" component={Wishlist} />
            <UserRoute exact path="/user/Checkout" component={Checkout} />
            <UserRoute exact path="/payment" component={Payment} />
            <UserRoute exact path="/myItems" component={MyItems} />
            <UserRoute exact path="/user/dashbord" component={Dashboard} />
            <UserRoute exact path="/user/profile" component={UserProfile} />
            <UserRoute
              exact
              path="/user/myitem-update/:id"
              component={MyItemUpdate}
            />
            {/* //new add */}
            <UserRoute exact path="/user/product" component={ProductCreate} />
            {/* //new add */}
            <SellerRoute exact path="/product" component={ProductCreate} />
            <SellerRoute
              exact
              path="/seller/shop-create"
              component={ShopCreate}
            />
            <SellerRoute exact path="/seller/all-shops" component={MyShops} />
            <SellerRoute
              exact
              path="/seller/dashboard/my-shop"
              component={ShopDashboard}
            />
            <SellerRoute
              exact
              path="/seller/shop-update/:id"
              component={ShopUpdate}
            />
            <SellerRoute path="/seller/category" component={SellerCatCreate} />
            <SellerRoute
              exact
              path="/seller/category-update/:slug"
              component={SellerCatUpdate}
            />
            <SellerRoute
              exact
              path="/seller/dashboard"
              // component={SellerDashboard}
              component={UserProfile}
            />
            <SellerRoute
              exact
              path="/seller/seller-dashboard"
              component={SellerDashboard}
            />
            <SellerRoute
              path="/seller/sub-create"
              component={SellerSubCreate}
            />
            <SellerRoute
              exact
              path="/seller/sub-update/:slg"
              component={SellerSubUpdate}
            />
            <SellerRoute exact path="/seller/myItems" component={SellerItems} />
            <SellerRoute exact path="/seller/myshops" component={SellerShops} />
            <Route exact path="/stripe/callback" component={StripeCallback} />

            <AdminRoute
              exact
              path="/admin/shop-create"
              component={AdminShopCreate}
            />
            <AdminRoute
              exact
              path="/admin/shop-update/:id"
              component={AdminShopUpdate}
            />

            <AdminRoute
              exact
              path="/admin/coupon"
              component={CreateCouponPage}
            />

            <AdminRoute
              exact
              path="/admin/product/:id"
              component={ProductUpdate}
            />
            <AdminRoute exact path="/admin/products" component={AllProducts} />
            <AdminRoute
              exact
              path="/admin/admin-dashboard"
              component={AdminDashboard}
            />
            <AdminRoute exact path="/admin/dashboard" component={UserProfile} />
            <AdminRoute
              exact
              path="/admin/category"
              component={CategoryCreate}
            />

            <AdminRoute
              exact
              path="/admin/category/:slug"
              component={CategoryUpdate}
            />
            <AdminRoute exact path="/admin/sub" component={SubCreate} />

            <AdminRoute exact path="/admin/sub/:slug" component={SubUpdate} />
            <AdminRoute
              exact
              path="/admin/upload-product"
              component={ProductCreate}
            />
            <AdminRoute
              exact
              path="/admin/users-option"
              component={UsersOption}
            />
            <AdminRoute
              exact
              path="/admin/try-on-pkg"
              component={TryOnPackage}
            />
          </Switch>
        </div>
        {/* <BottomBar /> */}
        {/* <Footer /> */}
      </Suspense>
    </div>
  );
}

export default App;

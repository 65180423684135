import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { SEARCH_QUERY } from "../../actions/types.js";
import { Input, Space } from "antd";

import "./Search.css";

const Search = () => {
  const dispatch = useDispatch();
  const { search } = useSelector((state) => ({ ...state }));
  const { text } = search;
  const [searchInput, setSearchInput] = useState("");

  const history = useHistory();

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    dispatch({
      type: SEARCH_QUERY,
      payload: { text: searchInput },
    });
    history.push(`/store?${text}`);
  };

  return (
    <form onSubmit={handleSubmit} className="flex">
      <input
        className="search-input"
        onChange={handleChange}
        type="search"
        value={searchInput}
      />
      <svg
        id="search-icon"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
        <path d="m21 21-6-6"></path>
      </svg>
      {/* <SearchOutlined onClick={handleSubmit} style={{}} /> */}
    </form>
  );
};

export default Search;
